import styled from 'styled-components';
import React, { useState, useEffect, useRef } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link';


const Tabs = ({ tabItems, type, ...props }) => {

    const [tab, setTab] = useState(null);
    const [tabIdx, setTabIdx] = useState(null);

    useEffect(() => {
        if (props.location.pathname === `${props.currentLocale}/company`) {
            if (props.location.hash === '') {
                props.location.hash = tabItems[0].hash;
                setTab('#Introduce');
            } else {
                setTab(props.location.hash)
            }
        } else {
            if (window.innerWidth < 767) {
                if (props.location.search.indexOf('idx') !== -1) {
                    setTabIdx(props.location.search.substring(props.location.search.length - 1));
                    ScrollBar(props.location.search.substring(props.location.search.length - 1), type)
                } else {
                    setTabIdx(null)
                    setTab(props.location.pathname)
                }
            } else setTab(props.location.pathname)
        }
        
        if (window.innerWidth > 768 && props.location.search.indexOf('listTop') !== -1) window.scrollTo(0, 200)
        if (window.innerWidth < 767 && props.location.search.indexOf('listTop') !== -1) window.scrollTo(0, 120)

    }, [])
    
    const ScrollBar = (idx, type) => {
        if (!idx) return
        else {
            let box = `${type}tab_${idx}`;
            document.getElementById(box).scrollIntoView(true);
        }
    }

    
    return (
        <Wrapper>
            <TabBox>
                {type === 'hashlink' ?
                    tabItems.map((item, idx) => {
                        
                        return (
                            <HashLink to={{ hash: item.hash }} key={String(idx)} id={`${type}tab_${idx}`}>
                                <Tab leng={item.title.length} active={tab === item.hash} onClick={e => {
                                    if (window.innerWidth < 767) ScrollBar(idx, type);
                                    setTab(item.hash)}}>{item.title}</Tab>
                            </HashLink>
                        )
                    })
                    :
                    tabItems.map((item, idx) => {
                        if (!item.title) return <a style={{pointerEvents: 'none'}}><div></div></a>;
                        return window?.innerWidth < 767 ? (
                            <a href={`${item.url}?y=listTop&idx=${idx}`} key={String(idx)} id={`${type}tab_${idx}`}>
                                <Tab leng={item.title.length} active={tabIdx ? tabIdx == idx : tab == item.url}>{item.title}</Tab>
                            </a>
                        ) : (
                            <a href={`${item.url}?y=listTop`} key={String(idx)} id={`${type}tab_${idx}`}>
                                <Tab leng={item.title.length} active={tab == item.url}>{item.title}</Tab>
                            </a>
                        )
                    })
                }
            </TabBox>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: sticky;
    top: 80px;
    width: 100%;
    margin: 30px auto;
    // padding: 10px 0;
    z-index: 20;
    background-color: #fff;

    @media screen and (max-width: 1200px) {
        padding: 6px 17px 0;
    }
    @media screen and (max-width: 767px) {
        top: 68px;
        margin: 10px auto 13px;
        padding: 6px 0 0;
    }
`

const TabBox = styled.div`
    display: flex;
    max-width: 1200px;
    margin: 0 auto;

    a {
        flex: 1;

        @media screen and (max-width: 767px) {
            flex: none;

            &:first-child {
                padding-left: 17px;
            }
            &:last-child {
                padding-right: 17px;
            }
        }
    }
    
    @media screen and (max-width: 1200px) {
        width: 100%;
    }

    @media screen and (max-width: 767px) {
        overflow-x: auto;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
    &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
    }
`

const HashLink = styled(NavHashLink)`
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    text-decoration : none !important;
`

const Tab = styled.div`
    position: relative;
    padding: 15px 0 16px;
    color: ${props => props.active ? '#222' : '#aaa'};
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border-radius: 12px;
    cursor: pointer;
    
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        ${props => props.active ? 'background-image: linear-gradient(92deg, #4e00ff -30%, #ac0093 109%)' : 'background-color: #fafafa'};
    }

    @media screen and (max-width: 767px) {
        font-size: 12px;
        padding: 15px 0 16px;
        width: ${props => props.leng < 8 ? '90px' : props.leng > 15 ? '160px' : '120px'}
    }
`


export default withRouter(Tabs)