import bathBg from '../../images/vr/titleBg_bathroom.png'
import bedBg from '../../images/vr/titleBg_bedroom.png'
import kitchenBg from '../../images/vr/titleBg_kitchen.png'
import laundryBg from '../../images/vr/titleBg_laundry.png'
import livingBg from '../../images/vr/titleBg_living.png'
import ApplicationItems from '../en/ApplicationItems'
import rsKitchenBg from '../../images/vr/rs_kitchen_bg.png'
import rsHallBg from '../../images/vr/rs_hall_bg.png'

const applications = {
    showroom: {
        title: 'VR Showroom',
        home: 'Home',
        cafeteria: 'Cafeteria',
        kitchen: 'Kitchen',
        laundry: 'Laundry Room',
        bedroom: 'Bedroom',
        living: 'Living Room',
        bath: 'Bathroom',
        hall: 'Hall',
    },
    header: {
        title: 'violeds | VIRTUAL SHOWROOM',
        desc: 'Our online showroom introduces you to Violeds products applied in various spaces. Take a look at how the products can be used in all areas of everyday life.',
        keyword: 'VR,VIRTUAL,SHOWROOM,violeds,uvled,applications'
    },
    category: ['All', 'Water Sterilization', 'Air Sterilization', 'Deodorization'],
    guide: {
        title: 'Virtual Showroom UI guide',
        desc: {
            pc: 'Click and drag the mouse left/right,\n up/down to explore the area.',
            mo: 'Touch and drag to explore the area.'
        },
        showroom: 'Our online showroom introduces you to Violeds products applied in various spaces.\nTake a look at how the products can be used in all areas of everyday life.'
    },
    data: {
        home: [{
            key: 'bedroom',
            name: 'Bedroom',
            url: '/en/showroom/bedroom',
            mbTitleBg: bedBg,
            items: [{
                name: 'Steam Closet',
                url: 'bedroom_item3',
                detail: ApplicationItems.product.steamCloset
            }, {
                name: 'Air purifier',
                url: 'bedroom_item2',
                detail: ApplicationItems.product.airpurifier
            }, {
                name: 'Air Conditioner',
                url: 'bedroom_item1',
                detail: ApplicationItems.product.airConditioner
            }, {
                name: 'Humidifier',
                url: 'bedroom_item4',
                detail: ApplicationItems.product.humidifier
            }]
        }, {
            key: 'kitchen',
            name: 'Kitchen',
            url: '/en/showroom/kitchen',
            mbTitleBg: kitchenBg,
            items: [{
                name: 'Refrigerator',
                url: 'kitchen_item2',
                detail: ApplicationItems.product.refrigerator
            }, {
                name: 'Water Purifier',
                url: 'kitchen_item1',
                detail: ApplicationItems.product.waterPurifier
            }, {
                name: 'Dishwasher',
                url: 'kitchen_item3',
                detail: ApplicationItems.product.dishwasher
            }]
        }, {
            key: 'living-room',
            name: 'Living Room',
            url: '/en/showroom/living-room',
            mbTitleBg: livingBg,
            items: [{
                name: 'Air Conditioner',
                url: 'living_room_item2',
                detail: ApplicationItems.product.airConditioner
            }, {
                name: 'Air purifier',
                url: 'living_room_item1',
                detail: ApplicationItems.product.airpurifier
            }]
        }, {
            key: 'laundry-room',
            name: 'Laundry Room',
            url: '/en/showroom/laundry-room',
            mbTitleBg: laundryBg,
            items: [{
                name: 'Washing Machine',
                url: 'laundry_item1',
                detail: ApplicationItems.product.washingMachine
            }, {
                name: 'Vacuum Cleaner',
                url: 'laundry_item2',
                detail: ApplicationItems.product.vacuumCleaner
            }]
        }, {
            key: 'bathroom',
            name: 'Bathroom',
            url: '/en/showroom/bathroom',
            mbTitleBg: bathBg,
            items: [{
                name: 'Bidet',
                url: 'bathroom_item1',
                detail: ApplicationItems.product.bidet
            }]
        }],

        cafeteria: [{
            key: 'rs-hall',
            name: 'Hall',
            url: '/en/showroom/rs-hall',
            mbTitleBg: rsHallBg,
            items: [{
                name: 'Air Conditioner',
                url: 'rs_hall_item1',
                detail: ApplicationItems.product.airConditioner
            }, {
                name: 'Steam Closet',
                url: 'rs_hall_item4',
                detail: ApplicationItems.product.steamCloset
            }, {
                name: 'Water Purifier',
                url: 'rs_hall_item3',
                detail: ApplicationItems.product.waterPurifier
            }, {
                name: 'Air purifier',
                url: 'rs_hall_item2',
                detail: ApplicationItems.product.airpurifier
            }]
        }, {
            key: 'rs-kitchen',
            name: 'Kitchen',
            url: '/en/showroom/rs-kitchen',
            mbTitleBg: rsKitchenBg,
            items: [{
                name: 'Water Purifier',
                url: 'rs_kitchen_item1',
                detail: ApplicationItems.product.waterPurifier
            }, {
                name: 'Refrigerator',
                url: 'rs_kitchen_item2',
                detail: ApplicationItems.product.refrigerator
            }, {
                name: 'Air purifier',
                url: 'rs_kitchen_item3',
                detail: ApplicationItems.product.airpurifier
            }]
        }]
    }
}

export default applications