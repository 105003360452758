import React, { useEffect, useState } from 'react'
import FirstShowroom from '../images/showroom/elevation_01.png'
import FirstShowroomMB from '../images/showroom/elevation_01_mb.png'
import bedroom_img from '../images/showroom/bedroom.png'
import bathroom_img from '../images/showroom/bathroom.png'
import livingroom_img from '../images/showroom/livingroom.png'
import kitchen_img from '../images/showroom/kitchen.png'
import laundry_img from '../images/showroom/laundry.png'
import pointer from '../images/showroom/ico_map_pointer.png'
import pointer_on from '../images/showroom/ico_map_pointer_on.png'
import styled, { keyframes } from 'styled-components';
import Fade from 'react-reveal/Fade';
import { Link, withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const First_Showroom = ({index, point, roomname}) => {
    const { t } = useTranslation('application_vr')
    const [titleOn1, setTitleON1] = useState(false);
    const [titleOn2, setTitleON2] = useState(false);
    const [titleOn3, setTitleON3] = useState(false);
    const [titleOn4, setTitleON4] = useState(false);
    const [titleOn5, setTitleON5] = useState(false);

    const [space1, setSpace1] = useState(true);
    const [space2, setSpace2] = useState(true);
    const [space3, setSpace3] = useState(true);
    const [space4, setSpace4] = useState(true);
    const [space5, setSpace5] = useState(true);
    
    let laundryRoom = t('data', { returnObjects: true }).home[3]
    let kitchen = t('data', { returnObjects: true }).home[1]
    let livingRoom = t('data', { returnObjects: true }).home[2]
    let bathRoom = t('data', { returnObjects: true }).home[4]
    let bedRoom = t('data', { returnObjects: true }).home[0] 

    useEffect(() => {
      if (point === 0) {
        setTimeout(()=>{ setSpace1(false) }, 1000);
        setTimeout(()=>{ setSpace2(false) }, 1300);
        setTimeout(()=>{ setSpace3(false) }, 1600);
        setTimeout(()=>{ setSpace4(false) }, 1900);
        setTimeout(()=>{ setSpace5(false) }, 2200);
        setTimeout(()=>{ setSpace3(true); setTitleON3(true);}, 2400);
      } else {
        setSpace1(true);
        setSpace2(true);
        setSpace3(true);
        setSpace4(true);
        setSpace5(true);
        setTitleON3(false);
      }
    },[point]);

    return (
      <Wrapper>
        <MoblieImgInner>
          <HomebgMB src={FirstShowroomMB} />
        </MoblieImgInner>
        <Homebg index={index}>
        {/* Item1 Bath Room position */}
        <Section1>
          <Fade top when={point === 0 ? true : false} 
                    distance="20%" 
                    duration={1000}>
            <Space1 src={bathroom_img} status={space1}/>
          </Fade>
        </Section1>
        <Point1>
          <Fade top when={point === 0 ? true : false} 
                    distance={point === 0 ? '180%' : '20%'} 
                    duration={1100}>
            <Title1 status={titleOn1}>{t('showroom.bath')}</Title1>
            <Link to={bathRoom.url}>
              <PointItemimg onMouseOver={() => { setTitleON1(true); setSpace1(true); setSpace3(false); setTitleON3(false); }} 
                            onMouseOut={() => { setTitleON1(false); setSpace1(false); }} 
                            status={titleOn1} />
            </Link>
          </Fade>
        </Point1>


        {/* Item2 Bed Room position */}
        <Section2>
          <Fade top when={point === 0 ? true : false} 
                    distance="20%" 
                    duration={1000} 
                    delay={point === 0 ? 300 : 0}>
            <Space2 src={bedroom_img} status={space2}/>
          </Fade>
        </Section2>
        <Point2>
          <Fade top when={point === 0 ? true : false} 
                    distance={point === 0 ? '180%' : '20%'} 
                    duration={1100} 
                    delay={point === 0 ? 300 : 0}>
            <Title2 status={titleOn2}>{t('showroom.bedroom')}</Title2>
            <Link to={bedRoom.url}>
              <PointItemimg onMouseOver={() => { setTitleON2(true); setSpace2(true); setSpace3(false); setTitleON3(false); }} 
                            onMouseOut={() => { setTitleON2(false); setSpace2(false) }}
                            status={titleOn2} />
            </Link>
          </Fade>
        </Point2>

        {/* Item3 Living Room position */}
        <Section3>
          <Fade top when={point === 0 ? true : false} 
                    distance="20%" 
                    duration={1000} 
                    delay={point === 0 ? 600 : 0}>
            <Space3 src={livingroom_img} status={space3}/>
          </Fade>
        </Section3>
        <Point3>
          <Fade top when={point === 0 ? true : false} 
                    distance={point === 0 ? '180%' : '20%'} 
                    duration={1100} 
                    delay={point === 0 ? 600 : 0}>
            <Title3 status={titleOn3}>{t('showroom.living')}</Title3>
            <Link to={livingRoom.url}>
              <PointItemimg onMouseOver={() => { setTitleON3(true); setSpace3(true) }} 
                            onMouseOut={() => { setTitleON3(false); setSpace3(false) }}
                            status={titleOn3} />
            </Link>
          </Fade>
        </Point3>
        
        
        {/* Item4 Kitchen position */}
        <Section4>
          <Fade top when={point === 0 ? true : false} 
                    distance="20%" 
                    duration={1000} 
                    delay={point === 0 ? 900 : 0}>
            <Space4 src={kitchen_img} status={space4}/>
          </Fade>
        </Section4>
        <Point4>
          <Fade top when={point === 0 ? true : false} 
                    distance={point === 0 ? '180%' : '20%'} 
                    duration={1100} 
                    delay={point === 0 ? 900 : 0}>
            <Title4 status={titleOn4}>{t('showroom.kitchen')}</Title4>
            <Link to={kitchen.url}>
              <PointItemimg onMouseOver={() => { setTitleON4(true); setSpace4(true); setSpace3(false); setTitleON3(false); }} 
                            onMouseOut={() => { setTitleON4(false); setSpace4(false) }}
                            status={titleOn4} />
            </Link>
          </Fade>
        </Point4>
          

        {/* Item5 Laundry Room position */}
        <Section5>
          <Fade top when={point === 0 ? true : false} 
                    distance="20%" 
                    duration={1000} 
                    delay={point === 0 ? 1200 : 0}>
            <Space5 src={laundry_img} status={space5}/>
          </Fade>
        </Section5>
        <Point5>
          <Fade top when={point === 0 ? true : false} 
                    distance={point === 0 ? '180%' : '20%'} 
                    duration={1100} 
                    delay={point === 0 ? 1200 : 0}>
            <Title5 status={titleOn5}>{t('showroom.laundry')}</Title5>
            <Link to={laundryRoom.url}>
              <PointItemimg onMouseOver={() => { setTitleON5(true); setSpace5(true); setSpace3(false); setTitleON3(false); }} 
                            onMouseOut={() => { setTitleON5(false); setSpace5(false) }}
                            status={titleOn5} />
            </Link>
          </Fade>
        </Point5>
        <RoomTextInner>
          <TextBox room={roomname}>
            <RoomText room={roomname}>{t('showroom.home')}</RoomText>
          </TextBox>
        </RoomTextInner>
        </Homebg>
        
      </Wrapper>
    )
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content : center;
  align-items: center;

  @media screen and (max-width: 900px) {
      width : 100%;
  }
`
const MoblieImgInner = styled.div`
    display : none;
    @media screen and (max-width: 900px) {
      display : block;
      width : 80%;
  }
`
const HomebgMB = styled.img`
    width : 100%;
`
const Homebg = styled.div`
  position : relative;
  width : 770px;
  height : 675px;
  background-image : url(${FirstShowroom});
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 900px) {
      display : none;
    }
`
const Section1 = styled.div`
  position : absolute;
  top:0;
  z-index : 10;
`

const Section2 = styled.div`
  position : absolute;
  top:0;
  z-index : 20;
`

const Section3 = styled.div`
  position : absolute;
  top:0;
  z-index : 30;
`

const Section4 = styled.div`
  position : absolute;
  top:0;
  z-index : 40;
`

const Section5 = styled.div`
  position : absolute;
  top:0;
  z-index : 50;
`

const Title1 = styled.div`
  font-size : 22px;
  font-weight : 600;
  text-shadow : 0 0 10px #000;
  color : #fff;
  transition : .3s;
  opacity : ${props => props.status ? 1 : 0};
`

const Space1 = styled.img`
  transition : 1s;
  opacity : ${props => props.status ? 1 : 0};
`
const bounce1 = keyframes`
  0% { top: 125px; }
  50% { top: 115px; }
  100% { top: 125px; }
`

const Point1 = styled.div`
  position : absolute;
  top : 125px;
  right: 305px;
  z-index : 200;
  display : flex;
  flex-direction : column;
  align-items : center;
  animation: ${bounce1} 2s infinite linear;
`

const Title2 = styled.div`
  font-size : 22px;
  font-weight : 600;
  text-shadow : 0 0 10px #000;
  color : #fff;
  transition : .3s;
  opacity : ${props => props.status ? 1 : 0};
`

const Space2 = styled.img`
  transition : 1s;
  opacity : ${props => props.status ? 1 : 0};
`

const bounce2 = keyframes`
  0% { top: 195px; }
  50% { top: 185px; }
  100% { top: 195px; }
`

const Point2 = styled.div`
  position : absolute;
  top: 195px;
  right: 240px;
  z-index : 200;
  display : flex;
  flex-direction : column;
  align-items : center;
  animation: ${bounce2} 2s infinite linear;
`

const Title3 = styled.div`
  font-size : 22px;
  font-weight : 600;
  text-shadow : 0 0 10px #000;
  color : #fff;
  transition : .3s;
  opacity : ${props => props.status ? 1 : 0};
`

const Space3 = styled.img`
  transition : 1s;
  opacity : ${props => props.status ? 1 : 0};
`

const bounce3 = keyframes`
  0% { top: 265px; }
  50% { top: 255px; }
  100% { top: 265px; }
`

const Point3 = styled.div`
  position : absolute;
  top: 265px;
  left: 270px;
  z-index : 200;
  display : flex;
  flex-direction : column;
  align-items : center;
  animation: ${bounce3} 2s infinite linear;
`

const Title4 = styled.div`
  font-size : 22px;
  font-weight : 600;
  text-shadow : 0 0 10px #000;
  color : #fff;
  transition : .3s;
  opacity : ${props => props.status ? 1 : 0};
`

const Space4 = styled.img`
  transition : 1s;
  opacity : ${props => props.status ? 1 : 0};
`

const bounce4 = keyframes`
  0% { top: 296px; }
  50% { top: 286px; }
  100% { top: 296px; }
`

const Point4 = styled.div`
  position : absolute;
  top: 296px;
  left: 180px;
  z-index : 200;
  display : flex;
  flex-direction : column;
  align-items : center;
  animation: ${bounce4} 2s infinite linear;
`

const Title5 = styled.div`
  font-size : 22px;
  font-weight : 600;
  text-shadow : 0 0 10px #000;
  color : #fff;
  transition : .3s;
  opacity : ${props => props.status ? 1 : 0};
`

const Space5 = styled.img`
  transition : 1s;
  opacity : ${props => props.status ? 1 : 0};
`

const bounce5 = keyframes`
  0% { top: 210px; }
  50% { top: 200px; }
  100% { top: 210px; }
`

const Point5 = styled.div`
  position : absolute;
  top: 210px;
  left: 75px;
  z-index : 200;
  display : flex;
  flex-direction : column;
  align-items : center;
  animation: ${bounce5} 2s infinite linear;
`

const PointItemimg = styled.div`
  width : 44px;
  height : 56px;
  background-image : url(${props => props.status ? pointer_on : pointer });
  background-repeat: no-repeat;
  background-size: cover;
  transition : .4s;
  cursor: pointer;
`

const RoomTextInner = styled.div`
  position : absolute;
  bottom : 0px;
  width : 100%;
  display : flex;
  justify-content : center;
`

const TextBox = styled.div`
  width: 206px;
  height: 52px;
  border-radius: 80px;
  background-color: #fff;
  display : flex;
  justify-content : center;
  align-items : center;
`

const RoomText = styled.div`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.33;
  color:  #000;
`


export default withRouter(First_Showroom)