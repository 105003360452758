import logo from '../../images/common/footer_logo_en.png'
import inquiryImg from '../../images/common/inquiry_icon.png'

export default {
    header: {
        gnb: {
            showroom: 'VIRTUAL SHOWROOM',
            about: 'ABOUT COMPANY',
            tech: 'TECHNOLOGY',
            applications: 'APPLICATIONS',
            media: 'MEDIA'
        },
        sideMenu: {
            review: 'Brand Review',
            tech: 'Technology',
            uv: 'UV Application Field',
            media: 'Media'
        },
        technology: {
            desc: 'Developed in collaboration between Seoul Viosys and SETi, UV LED clean technology can be utilized\nin applied products for the sterilization, purification and deodorization of water, air and surfaces.'
        },
        applications: {
            desc: 'New developments in Violeds are ongoing.\nStay updated with the fast-growing application case studies for Violeds.'
        },
        media: {
            desc: 'Now more than ever, Violeds has become essential in our everyday lives.\nMeet a more approachable Violeds through our different channels.'
        }
    },
    footer: {
        social: {
            facebook: {
                href: 'https://www.facebook.com/violedsTechnology',
                alt: 'facebook',
            },
            youtube: {
                href: 'https://www.youtube.com/c/violedstechnology',
                alt: 'youtube',
            },
            linkedin: {
                href: 'https://www.linkedin.com/company/seoulviosys/',
                alt: 'linkedin',
            }
        },
        logo: {
            image: logo,
            href: '/en',
            alt: 'Seoul Viosys'
        },
        site: {
            label: 'Family Sites',
            viosys: {
                title: 'Seoul Viosys',
                href: 'http://www.seoulviosys.com/en/'
            },
            seti: {
                title: 'SETi',
                href: 'http://www.s-et.com/en/'
            },
            semicon: {
                title: 'Seoul Semiconductor',
                href: 'http://www.seoulsemicon.com/en'
            }
        },
        menu: [{
            link : 'http://www.seoulviosys.com/en/company/contact/branch/',
            text : 'Offices'
        }, {
            link : 'http://www.seoulviosys.com/en/act/ask.asp',
            text : 'Request'
        }, {
            link : '/en/policy/privacy',
            text : 'Privacy Policy'
        }, {
            link : '/en/policy/ccpa',
            text : 'CCPA Privacy Notice'
        }, {
            link : '/en/policy/cookie',
            text : 'Cookie Policy'
        }, {
            link : 'http://www.seoulviosys.com/en/technology/legal/',
            text : 'Patent'
        }]    
    },
    inquiry: {
        img: inquiryImg,
        href: 'http://www.seoulviosys.com/en/support/contactus/',
        alt: 'move to inquiry page'
    }
}