import React, { useState, useEffect } from 'react'
import styled from 'styled-components';
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

// Images
import BannerCompany from '../images/banner/top_company.png'
import BannerCompanySmall from '../images/banner/top_company.small.png'
import BannerTech from '../images/banner/top_technology.png'
import BannerTechSmall from '../images/banner/top_technology_small.png'
import BannerApplications from '../images/banner/top_applications.png'
import BannerApplicationsSmall from '../images/banner/top_applications_small.png'
import BannerMedia from '../images/banner/top_media.png'
import BannerMediaSmall from '../images/banner/top_media_small.png'

// Test
// import BannerCompanySmall from '../images/top_technology_small.png'
// import BannerMediaSmaill from '../images/banner/top_technology_small.png'

//-------------------------
// Page
//-------------------------
const Banner = ({ type, ...props }) => {
    const { t } = useTranslation('common')
    const defwidth = [350, 767]
    const defBannerHeigth = [98, 200]
    const [bannerHeight, setBannerHeight] = useState(394)

    // Banner Items: company, tech, applications, media,
    const bannerItems = [{
        type: 'company',
        text: '',
        pc: BannerCompany,
        mobile: BannerCompanySmall
    }, {
        type: 'tech',
        text: t('header.technology.desc'),
        pc: BannerTech,
        mobile: BannerTechSmall
    }, {
        type: 'applications',
        text: t('header.applications.desc'),
        pc: BannerApplications,
        mobile: BannerApplicationsSmall
    }, {
        type: 'media',
        text: t('header.media.desc'),
        pc: BannerMedia,
        mobile: BannerMediaSmall
    }]
    let current = bannerItems[0]

    // Current
    for (var idx in bannerItems) {
        if (type === bannerItems[idx].type) current = bannerItems[idx]
    }

    //-------------------------
    // Functions
    //-------------------------
    // Size
    const handleSideSize = (def) => {
        const min = def[0]
        const max = def[1]
        const x = window.innerWidth
        if (typeof window !== 'undefined') {
            if (x < max) {
                const ratio = calcRatio(x, min, max)
                const result = calcBannerHeight(ratio, defBannerHeigth[0], defBannerHeigth[1])
                setBannerHeight(result)
            }
        }
    }

    // Calc Ratio
    const calcRatio = (x, min, max) => {
        return (x - min) / (max - min) * 100
    }

    // Calc Banner Height
    const calcBannerHeight = (x, min, max) => {
        let result = min + ((max - min) * x / 100)
        if (min < result) return result
        return min
    }

    

    //-------------------------
    // Use Effect
    //-------------------------
    useEffect(() => {
        handleSideSize(defwidth);
    }, [])

    useEffect(() => {
        const onSizeSize = () => {
            handleSideSize(defwidth);
        }

        window.addEventListener('resize', onSizeSize)
        return () => window.removeEventListener('resize', onSizeSize)
    }, [])

    //-------------------------
    // Render
    //-------------------------
    return (
        <Wrapper>
            <Background height={bannerHeight} pc={current.pc} mobile={current.mobile}>
                <Title dangerouslySetInnerHTML={{__html: current.text}} />
            </Background>
        </Wrapper>
    )
}

//-------------------------
// Style
//-------------------------
const Wrapper = styled.div`
    width: 100%;
`
const Background = styled.div`
    position: relative;
    width: 100%;
    margin-top: 77px;
    height: 160px;
    background: url(${(props) => props.pc}) no-repeat center;
    background-size: cover;

    @media (max-width: 767px) {
        height: ${(props) => props.height}px;
        margin-top: 68px;
        background: url(${(props) => props.mobile}) no-repeat center;
        background-size: cover;
    }
`
const Title = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    line-height: 1.5;
    color: #fff;
    text-align: center;
    white-space: pre-line;

    @media (max-width: 767px) {
        font-size: 14px;
        text-align: center;
    }

    @media (max-width: 567px) {
        font-size: 10px;
    }
`

export default withRouter(Banner)