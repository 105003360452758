import section3_img1 from '../../images/company/en_company_vision_01.png'
import section3_img2 from '../../images/company/en_company_vision_02.png'
import section3_img3 from '../../images/company/en_company_vision_03.png'
import section3_img4 from '../../images/company/en_company_vision_04.png'
import section3_img5 from '../../images/company/en_company_vision_05.png'
import section3_img6 from '../../images/company/en_company_vision_06.png'
import section3_img6_MB from '../../images/company/en_company_vision_06_MB.png'

export default {
    alt: [
        'Seoul Viosys company view',
        'CLEANLINESS',
        'COURTESY',
        'OBSERVANCE',
        'TRIM',
        'TEAMWORK',
        '10 EXECUTION VALUE'
    ],
    meta: {
        title: 'violeds | ABOUT COMPANY - About Us',
        desc: 'Seoul Viosys is a global company specializing in LEDS with approximately KRW 300 billion in sales as of 2016 and maintains a top-level position in the global LED market.',
        keyword: 'violeds,Violeds,violeds,About company,company introduction,seoul viosys'
    },
    tabs: [{
        hash: '#Introduce', 
        title: 'Seoul Viosys'
    }, {
        hash: '#message', 
        title: 'CEO Message'
    }, {
        hash: '#vision', 
        title: 'Vision'
    }, {
        hash: '#history', 
        title: 'History'
    }],
    section1: {
        title1: {
            left: 'Employees\'s',
            right: 'PRIDE',
            pwidth : '130px',
            mwidth : '105px',
        },
        title2: {
            left: 'Customer',
            right: 'VALUE',
            pwidth : '130px',
            mwidth : '105px',
        },
        title3: {
            left: 'Stockholder\'s',
            right: 'STABILITY',
            pwidth : '205px',
            mwidth : '165px',
        },
        subtitle: {
            pc: 'Furthermore, a company that is\nconstantly growing to help the world.',
            mo: 'Furthermore,\na company that is\nconstantly growing\nto help the world.'
        },
        desc: 'As of 2019, Seoul Viosys is a global LED specialist company with its sales revenue of about 300 billion won which belongs to the top class of LED market in the world. Approximately 4,000 patents lead the product market with differentiated technology, solidify the LED market, and provide LED products leading the current global sales network.'
    },
    section2: {
        title: {
            left: 'CEO',
            right: 'MESSAGE'
        },
        subtitle: 'For a New History of Light, and For the World without Technology Hijacking!',
        desc1: {
            author: 'Founder Lee Chung-hoon',
            content: 'Under the faith of “Let’s make lights helpful for customers and the World”, we have overcome many crisis to create a chance starting from a rental factory, and now we are the only company in the world developing and producing the light (photon) with all ranges of wavelength including the Infrared (IR) laser diode of VCSEL 1400nm, and UV LED 200nm. Now, we would like to present to mankind a breakthrough light that resembles the light of nature: a healthy light, a clean light, and a light beneficial to study.\n\nThe fact that Jimmy Carter, who was born as a youngest son of an ordinary farmer, became the president of the United States came into my life as a turning point, as I myself was born also as a son of a farmer. I have been dreaming a small but meaningful light masterpiece and hope to be the future young people dream of.We know the intellectual property used to be a motive for the 1st, 2nd and 3rd industrial revolution, contributing into reducing the percentage of the population in absolute poverty from 80% to 10%. We\'ve seen a drop in neonatal mortality from 43% to 3%, and that electricity supply made it possible for people to get 80% of their information through light, improving literacy and making life safer. I am working today with the last fighting spirit believing our company would become a case showing the IP would make our society fairer where class mobility is possible to make everyone\'s dream come true.',
            top : ''
        },
        desc2: {
            author: 'CEO Lee Young Ju',
            content: 'Thank you for your interest in Seoul Viosys. I believe a company should help the world, create value for its customers, let employees have pride and ensure the stability for its shareholders.\n\nSeoul Viosys was established in 2002 for the UV LED business and started co-development and production for UVA(Ultraviolet rays) with NS, the Japanese company. In 2005, it cooperated with SETi in the capital and developed UV UVC (Early 400nm) for the first time in the world. Since then, we have focused on UV LEDs for over 15 years and have pioneered numerous new patented technology products and applications, supplying core devices for special applications such as space station, medical industry, diagnosis, skin health and BIO field.\n\nIn 2015, the US Department of Defense reviewed the International Weapons Trading Regulations (ITAR) and was approved by the US Foreign Investment Committee (CFIUS). Now, by combining the new technology of various application fields developed by Seoul Viosys and the mass production technology of Seoul Semiconductor Co., Ltd. to supply to the world at a remarkable price by cost innovation to help the human life in earnest, A clean world for the earth people! A healthy world! We want to create beautiful world!',
            top : '100px'
        }
    },
    section3: {
        title: 'VISION',
        subtitle: 'WORLD TOP LED',
        standard: [
            section3_img1,
            section3_img2,
            section3_img3,
            section3_img4,
            section3_img5
        ],
        execution: {
            image: {
                pc: section3_img6,
                mo: section3_img6_MB
            }
        }
    },
    section4: {
        title: 'HISTORY',
        subtitle: 'We make the WORLD TOP LED history',
        items: [{
            year: '2016',
            desc: '▪︎ violeds technology basis complete product released'
        }, {
            year: '2015',
            desc: '▪︎ Acquisition of SETi and subsequent merger of SETi and Nitek\n▪︎ Announced WiCOP2(Flip-chip)'
        }, {
            year: '2014',
            desc: '▪︎ Registered over 4,000 patents\n▪︎ Expansion of ‘violeds’ technology\n▪︎ Reached manufacturing capability of a million chips per month'
        }, {
            year: '2013',
            desc: '▪︎ Launched New company name ‘SEOUL VIOSYS’\n▪︎ Launched UV system Solution\n▪︎ High power NEW UVA (365nm, 375nm, 385 nm, 395nm)'
        }, {
            year: '2012',
            desc: '▪︎ Announced “nPola” that increases brightness more than five times\n▪︎ Annonced WICOP(Flip-chip)'
        }, {
            year: '2011',
            desc: '▪︎ Launched mass-production of V-Chip'
        }, {
            year: '2010',
            desc: '▪︎ Concluded MOU with Gyeonggi Province and Ansan City\n▪︎ Prof. Shuji Nakamura, the world’s greatest LED lighting scholar started consulting'
        }, {
            year: '2009',
            desc: '▪︎ Launched mass production of chips for LED TV use.\n▪︎ Agreed to a settlement with Nichia and signed a cross license.\n▪︎ Volume production of over 1 billion of chips (Visible + UV)'
        }, {
            year: '2008',
            desc: '▪︎ ISO 14000 acquired\n▪︎ Acrich awarded the grand prize from 2008 Korea Technology Contest and designated as the world top 10\n▪︎ RU certificate obtained from UL, USA for the first time in Acrich LED market'
        }, {
            year: '2007',
            desc: '▪︎ ISO / TS_16949 acquired\n▪︎ Launched the first mass production of 255nm~340nm Deep UV LED in the world.\n▪︎ Launched the mass production of Acrich Chip'
        }, {
            year: '2005',
            desc: '▪︎ Strategic investment in a joint venture company SETi, in US.\n▪︎ World’s first mass production of Deep UV (275nm, 310nm, 340nm)\n▪︎ Developed Acrich chip for the first time in the world'
        }, {
            year: '2004',
            desc: '▪︎ ISO–9001, QS-9000 acquired'
        }, {
            year: '2003',
            desc: '▪︎ Jointly developed high brightness LED with a major university in US.'
        }, {
            year: '2002',
            desc: '▪︎ Launched to develop EPI, FAB, UV LED\n▪︎ Strategic partnership with Japanese company on corporation agreement of UV LED\n▪︎ Seoul Optodevice was established as Seoul Semiconductor’s technology enabler'
        }]
    }
}