import logo from '../../images/common/footer_logo_en.png'
import inquiryImg from '../../images/common/inquiry_icon_jp.png'

export default {
    header: {
        gnb: {
            showroom: 'VRショールーム',
            about: '企業情報',
            tech: '技術',
            applications: '応用アプリケーション',
            media: '新着情報'
        },
        sideMenu: {
            review: 'Violeds 紹介',
            tech: '技術',
            uv: 'UVアプリケーション分野',
            media: '新着情報'
        },
        technology: {
            desc: 'ソウルバイオシスとSETi社が共同開発したUV LED清浄技術で、\n水、空気、表面などの除菌および浄化、消臭のための応用製品に活用できます。'
        },
        applications: {
            desc: 'violeds技術の開発は日々続いています。 \n急速に拡大していくVioledsの適用例の最新情報を提供致します。'
        },
        media: {
            desc: '今やvioleds技術は私たちの日々の生活にとって不可欠なものとなっています。\n私たちが持つ様々なチャネルを通じてより身近になったvioleds技術をお試しください。'
        }
    },
    footer: {
        social: {
            facebook: {
                href: 'https://www.facebook.com/violedsTechnology',
                alt: 'facebook',
            },
            youtube: {
                href: 'https://www.youtube.com/c/violedstechnology',
                alt: 'youtube',
            },
            linkedin: {
                href: 'https://www.linkedin.com/company/seoulviosys/',
                alt: 'linkedin',
            }
        },
        logo: {
            image: logo,
            href: '/jp',
            alt: 'Seoul Viosys'
        },
        site: {
            label: 'Family Sites',
            viosys: {
                title: 'Seoul Viosys',
                href: 'http://www.seoulviosys.com/jp/'
            },
            seti: {
                title: 'SETi',
                href: 'http://www.s-et.com/en/'
            },
            semicon: {
                title: 'Seoul Semiconductor',
                href: 'http://www.seoulsemicon.com/jp'
            }
        },
        menu: [{
            link : 'http://www.seoulviosys.com/jp/company/contact/branch/',
            text : 'Offices'
        }, {
            link : 'http://www.seoulviosys.com/jp/act/ask.asp',
            text : 'Request'
        }, {
            link : '/jp/policy/privacy',
            text : 'Privacy Policy'
        }, {
            link : 'http://www.seoulviosys.com/jp/technology/legal/',
            text : 'Patent'
        }]    
    },
    inquiry: {
        img: inquiryImg,
        href: 'http://www.seoulviosys.com/jp/support/contactus/',
        alt: 'move to inquiry page'
    }
}