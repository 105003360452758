import bathBg from '../../images/vr/titleBg_bathroom.png'
import bedBg from '../../images/vr/titleBg_bedroom.png'
import kitchenBg from '../../images/vr/titleBg_kitchen.png'
import laundryBg from '../../images/vr/titleBg_laundry.png'
import livingBg from '../../images/vr/titleBg_living.png'
import ApplicationItems from '../en/ApplicationItems'
import rsKitchenBg from '../../images/vr/rs_kitchen_bg.png'
import rsHallBg from '../../images/vr/rs_hall_bg.png'

const applications = {
    showroom: {
        title: 'VRショールーム',
        home: '住居空間',
        cafeteria: '食堂',
        kitchen: 'キッチン',
        laundry: '洗濯室',
        bedroom: '寝室',
        living: 'リビングルーム',
        bath: '浴室',
        hall: 'ホール'
    },
    header: {
        title: 'violeds | VIRTUAL SHOWROOM',
        desc: 'オンラインショールームを通じて様々な空間で活用されるvioleds製品を紹介します。 日常生活のあらゆる分野に適用できる応用製品をご紹介します！',
        keyword: 'VR,VIRTUAL,SHOWROOM,violeds,uvled,applications'
    },
    category: ['全体', '水除菌', '空気除菌', '脱臭技術情報'],
    guide: {
        title: 'VRショールーム\nUIガイド',
        desc: {
            pc: 'マウスのクリックとドラッグで\n自由に空間を移動してください。',
            mo: '画面をタッチするかドラッグして\n自由に空間を移動してください。'               //일본어번역 필요
        },
        showroom: 'オンラインショールームを通じて様々な空間で活用されるvioleds製品を紹介します。\n日常生活のあらゆる分野に適用できる応用製品をご紹介します！'
    },
    data: {
        home: [{
            key: 'bedroom',
            name: '寝室',
            url: '/jp/showroom/bedroom',
            mbTitleBg: bedBg,
            items: [{
                name: '衣類管理機',
                url: 'bedroom_item3',
                detail: ApplicationItems.product.steamCloset
            }, {
                name: '空気清浄機',
                url: 'bedroom_item2',
                detail: ApplicationItems.product.airpurifier
            }, {
                name: 'エアコン',
                url: 'bedroom_item1',
                detail: ApplicationItems.product.airConditioner
            }, {
                name: '加湿器',
                url: 'bedroom_item4',
                detail: ApplicationItems.product.humidifier
            }]
        }, {
            key: 'kitchen',
            name: 'キッチン',
            url: '/jp/showroom/kitchen',
            mbTitleBg: kitchenBg,
            items: [{
                name: '冷蔵庫',
                url: 'kitchen_item2',
                detail: ApplicationItems.product.refrigerator
            }, {
                name: '浄水器',
                url: 'kitchen_item1',
                detail: ApplicationItems.product.waterPurifier
            }, {
                name: '食器洗浄機',
                url: 'kitchen_item3',
                detail: ApplicationItems.product.dishwasher
            }]
        }, {
            key: 'living-room',
            name: 'リビングルーム',
            url: '/jp/showroom/living-room',
            mbTitleBg: livingBg,
            items: [{
                name: 'エアコン',
                url: 'living_room_item2',
                detail: ApplicationItems.product.airConditioner
            }, {
                name: '空気清浄機',
                url: 'living_room_item1',
                detail: ApplicationItems.product.airpurifier
            }]
        }, {
            key: 'laundry-room',
            name: '洗濯室',
            url: '/jp/showroom/laundry-room',
            mbTitleBg: laundryBg,
            items: [{
                name: '洗濯機',
                url: 'laundry_item1',
                detail: ApplicationItems.product.washingMachine
            }, {
                name: '掃除機',
                url: 'laundry_item2',
                detail: ApplicationItems.product.vacuumCleaner
            }]
        }, {
            key: 'bathroom',
            name: '浴室',
            url: '/jp/showroom/bathroom',
            mbTitleBg: bathBg,
            items: [{
                name: '温水洗浄便座',
                url: 'bathroom_item1',
                detail: ApplicationItems.product.bidet
            }]
        }],

        cafeteria: [{
            key: 'rs-hall',
            name: 'ホール',
            url: '/jp/showroom/rs-hall',
            mbTitleBg: rsHallBg,
            items: [{
                name: 'エアコン',
                url: 'rs_hall_item1',
                detail: ApplicationItems.product.airConditioner
            }, {
                name: '衣類管理機',
                url: 'rs_hall_item4',
                detail: ApplicationItems.product.steamCloset
            }, {
                name: '浄水器',
                url: 'rs_hall_item3',
                detail: ApplicationItems.product.waterPurifier
            }, {
                name: '空気清浄機',
                url: 'rs_hall_item2',
                detail: ApplicationItems.product.airpurifier
            }]
        }, {
            key: 'rs-kitchen',
            name: 'キッチン',
            url: '/jp/showroom/rs-kitchen',
            mbTitleBg: rsKitchenBg,
            items: [{
                name: '浄水器',
                url: 'rs_kitchen_item1',
                detail: ApplicationItems.product.waterPurifier
            }, {
                name: '冷蔵庫',
                url: 'rs_kitchen_item2',
                detail: ApplicationItems.product.refrigerator
            }, {
                name: '空気清浄機',
                url: 'rs_kitchen_item3',
                detail: ApplicationItems.product.airpurifier
            }]
        }]
    }
}

export default applications