import React from 'react'
import styled from 'styled-components'
import MediaPrev from '../images/main/main_media_prev.png'
import MediaNext from '../images/main/main_media_next.png'
import { Switch, Case } from 'react-if';

//-------------------------
// Page
//-------------------------
const MainMediaArrow = ({ type }) => {
    //-------------------------
    // Render
    //-------------------------
    return (
        <Wrapper>
            <Switch>
                <Case condition={type === 'prev'}>
                    <PrevArrow  />
                </Case>
                <Case condition={type === 'next'}>
                    <NextArrow />
                </Case>
            </Switch>
        </Wrapper>
    )
}

//-------------------------
// Style
//-------------------------
const Wrapper = styled.div`
    position: relative;
    width: 100%;
`

//-------------------------
// Wrap
//-------------------------
const PrevArrow = styled.div`
    width: 160px;
    height: 160px;
    background: url(${MediaPrev}) no-repeat center;
    background-size: cover;
    z-index: 999;
    transition: .3s;
    opacity: 0.2;
    &:hover {
        opacity: 1.0;
    }
`
const NextArrow = styled.div`
    width: 160px;
    height: 160px;
    background: url(${MediaNext}) no-repeat center;
    background-size: cover;
    z-index: 999;
    transition: .3s;
    opacity: 0.2;
    &:hover {
        opacity: 1.0;
    }
`

export default MainMediaArrow