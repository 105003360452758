import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

//-------------------------
// Page
//-------------------------
const TechFeature = ({ data }) => {
    const contents = data.contents
    const [isMobile, setIsMobile] = useState(false)

    //-------------------------
    // Functions
    //-------------------------
    // Size
    const handleSideSize = () => {
        if (window.innerWidth < 768) setIsMobile(true)
        else setIsMobile(false)
    }

    //-------------------------
    // Use Effect
    //-------------------------
    useEffect(() => {
        handleSideSize()
        window.addEventListener('resize', handleSideSize)

        return () => {
            window.addEventListener('resize', handleSideSize)
        }
    })

    //-------------------------
    // Render
    //-------------------------
    return (
        <Wrapper>
        {(contents && contents.length) ? contents.map((item, idx) => {
            return (
                <Feature key={idx}>
                    <Image src={item.image} alt={item.alt} />
                    <Wrap>
                        <Title>{item.title}</Title>
                        {isMobile ?
                            <Desc dangerouslySetInnerHTML={{__html: item.descMobile}} /> :
                            <Desc dangerouslySetInnerHTML={{__html: item.desc}} />
                        }
                    </Wrap>
                </Feature>
            )
        }) : ( <div></div> )}
        </Wrapper>
    )
}

//-------------------------
// Style
//-------------------------
const Wrapper = styled.div`
    width: 100%;
`

//-------------------------
// Feature
//-------------------------
const Feature = styled.div`
    display: flex;
    width: 100%;
    margin-top: 25px;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 22px;
    }

    @media (max-width: 767px) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`
const Image = styled.img`
    width: 100px;
    height: 100px;

    @media (max-width: 374px) {
        width: 80px;
        height: 80px;
    }
`
const Wrap = styled.div`
    flex: 1;
    padding: 0px 10px 16px 45px;

    @media (max-width: 767px) {
        padding: 0px 30px;
    }

    @media (max-width: 567px) {
        padding: 0px 20px;
    }

    @media (max-width: 374px) {
        padding: 0px 10px;
    }
`
const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #222;

    @media (max-width: 767px) {
        text-align: center;
    }

    @media (max-width: 567px) {
        font-size: 16px;
    }
`
const Desc = styled.div`
    margin-top: 10px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #222;

    @media (max-width: 767px) {
        text-align: center;
    }

    @media (max-width: 567px) {
        font-size: 14px;
    }
`

export default TechFeature