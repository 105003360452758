import React from 'react'
import IndexView from '../views/IndexView'
import { withRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'


const Main = ({ ...props }) => {
    const { t } = useTranslation('main')

    return (
        <IndexView {...props} t={t}></IndexView>
    )
}


export default withRouter(Main)