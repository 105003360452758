import styled from 'styled-components';
import React, { useState, useEffect } from 'react'
import { Link, withRouter, useLocation } from 'react-router-dom'
import ApplicationList from '../components/ApplicationList'
import Tabs from '../components/Tabs'
import Banner from '../components/Banner'
import { Fade } from 'react-reveal';
import Head from '../components/Head'
import { useTranslation } from 'react-i18next'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const Applications = ({ ...props }) => {

    const { t } = useTranslation('application')

    const dataSteril = t('data', { returnObjects: true }).filter(a => a.type.find(el => el == '살균'))
    const dataDeo = t('data', { returnObjects: true }).filter(a => a.type.find(el => el == '탈취'))
    const dataAttr = t('data', { returnObjects: true }).filter(a => a.type.find(el => el == '포충'))
    const dataCur = t('data', { returnObjects: true }).filter(a => a.type.find(el => el == '경화'))

    const query = useQuery()

    const tabItems = [{
        url: `${props.currentLocale}/applications`, 
        title: t('type', { returnObjects: true })[0]
    }, {
        url: `${props.currentLocale}/applications/sterilization`, 
        title: t('type', { returnObjects: true })[1]
    }, {
        url: `${props.currentLocale}/applications/deodorization`, 
        title: t('type', { returnObjects: true })[2]
    }, {
        url: `${props.currentLocale}/applications/insect-attraction`, 
        title: t('type', { returnObjects: true })[3]
    }, {
        url: `${props.currentLocale}/applications/curing`, 
        title: t('type', { returnObjects: true })[4]
    }]

    return (
        <Wrapper>
            <Banner type='applications' />

            <Contents id="appTop">
                <Tabs tabItems={tabItems} type={'app'} />

                <AppList>
                    {props.location.pathname == `${props.currentLocale}/applications` &&
                        t('data', { returnObjects: true }).map((item, idx) => {
                            return (
                                <AppWarp id={item.keyword} key={idx}>
                                    <Head
                                        title={t('header.all.title')}
                                        description={t('header.all.desc')}
                                        keyword={t('header.all.keyword')}
                                        lang={props.currentLocale} />
                                    <Fade bottom duration={1500} delay={200}>
                                        <ApplicationList current={query.get('product')} data={item} techPC={t('techBtn.pc', { returnObjects: true })} techMO={t('techBtn.mo', { returnObjects: true })} currentLocale={props.currentLocale} props={props.location} />
                                    </Fade>
                                </AppWarp>
                            )
                        })
                    }

                    {props.location.pathname == `${props.currentLocale}/applications/sterilization` &&
                        dataSteril.map((item, idx) => {
                            return (
                                <AppWarp id={item.keyword} key={idx}>
                                    <Head
                                        title={t('header.sterilization.title')}
                                        description={t('header.sterilization.desc')}
                                        keyword={t('header.sterilization.keyword')}
                                        lang={props.currentLocale} /> 
                                    <Fade bottom duration={1500} delay={200}>
                                        <ApplicationList current={query.get('product')} data={item} techPC={t('techBtn.pc', { returnObjects: true })} techMO={t('techBtn.mo', { returnObjects: true })} currentLocale={props.currentLocale} props={props.location} />
                                    </Fade>
                                </AppWarp>
                            )
                        })
                    }

                    {props.location.pathname == `${props.currentLocale}/applications/deodorization` &&
                        dataDeo.map((item, idx) => {
                            return (
                                <AppWarp id={item.keyword} key={idx}>
                                    <Head
                                        title={t('header.deodorization.title')}
                                        description={t('header.deodorization.desc')}
                                        keyword={t('header.deodorization.keyword')}
                                        lang={props.currentLocale}
                                        />
                                    <Fade bottom duration={1500} delay={200}>
                                        <ApplicationList current={query.get('product')} data={item} techPC={t('techBtn.pc', { returnObjects: true })} techMO={t('techBtn.mo', { returnObjects: true })} currentLocale={props.currentLocale} props={props.location} />
                                    </Fade>
                                </AppWarp>
                            )
                        })
                    }

                    {props.location.pathname == `${props.currentLocale}/applications/insect-attraction` &&
                        dataAttr.map((item, idx) => {
                            return (
                                <AppWarp id={item.keyword} key={String(idx)}>
                                    <Head
                                        title={t('header.insectAttraction.title')}
                                        description={t('header.insectAttraction.desc')}
                                        keyword={t('header.insectAttraction.keyword')}
                                        lang={props.currentLocale} />
                                    <Fade bottom duration={1500} delay={200}>
                                        <ApplicationList current={query.get('product')} data={item} techPC={t('techBtn.pc', { returnObjects: true })} techMO={t('techBtn.mo', { returnObjects: true })} currentLocale={props.currentLocale} props={props.location} />
                                    </Fade>
                                </AppWarp>
                            )
                        })
                    }

                    {props.location.pathname == `${props.currentLocale}/applications/curing` &&
                        dataCur.map((item, idx) => {
                            return (
                                <AppWarp id={item.keyword} key={idx}>
                                    <Head
                                        title={t('header.curing.title')}
                                        description={t('header.curing.desc')}
                                        keyword={t('header.curing.keyword')}
                                        lang={props.currentLocale} />
                                    <ApplicationList current={query.get('product')} data={item} techPC={t('techBtn.pc', { returnObjects: true })} techMO={t('techBtn.mo', { returnObjects: true })} currentLocale={props.currentLocale} props={props.location} />
                                </AppWarp>
                            )
                        })
                    }
                </AppList>
            </Contents>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`

const Contents = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 325px;
`

const AppList = styled.ul`
    width: 100%;
    padding-top: 10px;
    margin-bottom: -130px;
`

const AppWarp = styled.li`
    padding-bottom: 36px;

    &::before {
        display: block; 
        content: " "; 
        margin-bottom: -685px; 
        height: 685px; 
        visibility: hidden; 
        pointer-events: none;
    }
`

export default withRouter(Applications)