import styled from 'styled-components';
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import Banner from '../components/Banner'
import arrowLeft from '../images/common/list_arrow_left@3x.png'
import arrowRight from '../images/common/list_arrow_right@3x.png'
import listIco from '../images/common/list_ico.png'
import cardLeft from '../images/common/media_arrow_left.png'
import cardRight from '../images/common/media_arrow_right.png'
import { getMediaDetail, getMediaPreNext, getMediaRelative } from '../libs/media'
import moment from 'moment'
import Head from '../components/Head'
import { useTranslation } from 'react-i18next'
import { Space } from 'antd'

const MediaDetail = ({ ...props }) => {

    const { t } = useTranslation('media')

    const [type, setType] = useState();
    const [data, setData] = useState();
    const [slideCurrent, setSlideCurrent] = useState(1);
    const [prenextData, setPrenextData] = useState(null);
    const [relativeData, setRelativeData] = useState(null);

    const [title, setTitle] = useState('')
    const [description, setDescription] = useState('')
    
    useEffect(() => {
        const callMediaDetail = async() => {
            try {
                const result = await getMediaDetail({mediaIdx: props.match.params.pid}, t('api.detail'));
                if (result.status == 200) {
                    setData(result.data)
                    setType(JSON.parse(result.data.content).type)
                    
                    let category = result.data.category;
                    if (props.location.search.indexOf('all') !== -1) {
                        category = 'all';
                    }
                    
                    // 이전-다음 콘텐츠 불러오기
                    try {
                        const resultPreNext = await getMediaPreNext({mediaIdx: props.match.params.pid, category: category}, t('api.preNext'));
                        if (resultPreNext.status === 200) {
                            setPrenextData(resultPreNext.data)
                        }
                    } catch (err) {    
                    }

                    // 연관 콘텐츠 불러오기
                    try {
                        const resultRelative = await getMediaRelative({category: result.data.category}, t('api.related'));
                        if (resultRelative.status === 200) {
                            setRelativeData(resultRelative.data)
                        }
                    } catch (err) {
                    }

                    // console.log(props.match.params.pid, category, t('api.preNext'), resultPreNext.data)
                    setTitle(result.data.title)
                    setDescription(result.data.summary)
                }
            } catch (err) {
                
            }
        }
        
        callMediaDetail();
    }, [])


    const settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: '350px',
        slidesToShow: 1,
        speed: 600,
        // autoplay: true,
        // autoplaySpeed: 3000,
        focusOnSelect: true,
        onInit: () => {
            const slide = document.querySelector('.slick-current');
            slide.classList.add('centerSlide')
        },
        beforeChange: (prev, next) => {
            const slides = document.querySelectorAll('.slick-slide');
            for (let i = 0; i < slides.length; i++) {
                slides[i].classList.remove('centerSlide')
            }
            setTimeout(() => {
                // 첫번째 슬라이드 -> 마지막
                if (next == JSON.parse(data.content).cardList.length - 1) slides[1].classList.add('centerSlide')

                // 마지막 슬라이드 -> 첫번째
                if (next == 0) slides[JSON.parse(data.content).cardList.length + 2].classList.add('centerSlide')
                slides[next + 2].classList.add('centerSlide')
            }, 80)
            // console.log(next, slides.length)
        },
        afterChange: (index) => {
            setSlideCurrent(index + 1)
            // console.log('index', index)
        },
        responsive: [{
            breakpoint: 1199,
            settings: {
                centerPadding: '300px',
            }
        }, {
            breakpoint: 1000,
            settings: {
                centerPadding: '200px',
            }
        }, {
            breakpoint: 800,
            settings: {
                centerPadding: '100px',
            }
        }, {
            breakpoint: 767,
            settings: {
                centerMode: false,
            }
        }]
    };

    return (
        <Wrapper>
            <Head
                title={`violeds | ${title}`}
                description={description}
                keyword={''}
                lang={props.currentLocale}
                />
            <Banner type='media' />
            
            {data && prenextData && relativeData ?
            <>
            <Contents>
                {type == 'article' &&
                <>
                    <DetailTitle word={props.currentLocale}>{data.title}</DetailTitle>
                    <DetailInfo>
                        <DetailDate>{moment(data.createdAt).format('YYYY-MM-DD')}</DetailDate>
                        <DetailViews>Views {data.viewCount}</DetailViews>
                    </DetailInfo>
                    <DetailContents>
                        <DetailThumb src={data.thumbnail} alt={data.title} />
                        {JSON.parse(data.content).content.map((item, idx) => {
                            return (
                                <DetailConWrap key={`contents_${idx}`}>
                                    {item.type == 'text' &&
                                        <DetailDesc word={props.currentLocale} dangerouslySetInnerHTML={{__html: item.content}}></DetailDesc>
                                    }
                                    {item.type == 'image' &&
                                        <>
                                            <DetailImg src={item.content} alt={item.caption}></DetailImg>
                                            <DetailImgCaption>{item.caption}</DetailImgCaption>
                                        </>
                                    }
                                </DetailConWrap>
                            )
                        })
                        }
                    </DetailContents>
                </>
                }


                {type == 'video' &&
                <>
                    <DetailTitle word={props.currentLocale}>{data.title}</DetailTitle>
                    <DetailInfo>
                        <DetailDate>{moment(data.createdAt).format('YYYY-MM-DD')}</DetailDate>
                        <DetailViews>Views {data.viewCount}</DetailViews>
                    </DetailInfo>
                    <DetailContents>
                        <DetailVideo>
                            <iframe src={`https://www.youtube.com/embed/${JSON.parse(data.content).videoId}`} allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        </DetailVideo> 
                        {JSON.parse(data.content).content.map((item, idx) => {
                            return (
                                <DetailConWrap key={`contents_${idx}`}>
                                    {item.type == 'text' &&
                                        <DetailDesc word={props.currentLocale} dangerouslySetInnerHTML={{__html: item.content}}></DetailDesc>
                                    }
                                </DetailConWrap>
                                )
                            })
                        }
                    </DetailContents>
                </>
                }


                {type == 'card' &&
                <>
                    <DetailTitle word={props.currentLocale}>{data.title}</DetailTitle>
                    <DetailInfo>
                        <DetailDate>{moment(data.createdAt).format('YYYY-MM-DD')}</DetailDate>
                        <DetailViews>Views {data.viewCount}</DetailViews>
                    </DetailInfo>
                    <DetailContents>
                        <SlideWrap>
                            <Slider {...settings}>
                                {
                                    JSON.parse(data.content).cardList.map((item, idx) => {
                                        return <CardItem key={`cardList_${idx}`} img={item} />
                                    })
                                }
                            </Slider>
                            <CardNum>{slideCurrent} / {JSON.parse(data.content).cardList.length}</CardNum>
                        </SlideWrap>
                        {JSON.parse(data.content).content.map((item, idx) => {
                            return (
                                <DetailConWrap key={`contents_${idx}`}>
                                    {item.type == 'text' &&
                                        <DetailDesc word={props.currentLocale} dangerouslySetInnerHTML={{__html: item.content}}></DetailDesc>
                                    }
                                </DetailConWrap>
                                )
                            })
                        }
                    </DetailContents>
                </>
                }

            </Contents>

            <ItemListWrap>
                <ItemList>
                    {prenextData?.pre ? 
                        <ListWrap href={`${props.currentLocale}/media/${prenextData.pre.mediaIdx}`}>
                            <ListArrow src={arrowLeft} alt={t('list.prev')} />
                            <ListTxt>
                                <Order>{t('list.prev')}</Order>
                                <ListTitle word={props.currentLocale}>{prenextData.pre.title}</ListTitle>
                            </ListTxt>
                        </ListWrap>
                        :
                        <EmptyData>
                            <ListArrow as={'div'} />
                            <ListTxt>
                                <Order>{t('list.prev')}</Order>
                                <ListTitle word={props.currentLocale}>{t('list.noPrev')}</ListTitle>
                            </ListTxt>
                        </EmptyData>
                    }
                    <ListCenter href={`${props.currentLocale}/media`}>
                        <ListIco src={listIco} alt={t('list.goList')} />
                        <ListCenterTxt>{t('list.golist')}</ListCenterTxt>
                    </ListCenter>

                    {prenextData?.next ? 
                        <ListWrapRight href={`${props.currentLocale}/media/${prenextData.next.mediaIdx}`}>
                            <ListTxtRight>
                                <Order>{t('list.next')}</Order>
                                <ListTitle word={props.currentLocale}>{prenextData.next.title}</ListTitle>
                            </ListTxtRight>
                            <ListRightArrow src={arrowRight} alt={t('list.next')} />
                        </ListWrapRight>
                        :
                        <EmptyDataRight>
                            <ListTxtRight>
                                <Order>{t('list.next')}</Order>
                                <ListTitle word={props.currentLocale}>{t('list.noNext')}</ListTitle>
                            </ListTxtRight>
                            <ListRightArrow as={'div'} />
                        </EmptyDataRight>
                    }
                </ItemList>
            </ItemListWrap>

            <RelatedContents>
                <RelatedTop>{t('list.related')}</RelatedTop>
                <RelatedItemWrap>
                    {relativeData?.map(item => {
                        return (
                            <RelatedItem href={`${props.currentLocale}/media/${item.mediaIdx}`} key={`related_${item.mediaIdx}`}>
                                <RelatedThumb img={item.thumbnail} />
                                <RelatedTxt>
                                    <RelatedTitle word={props.currentLocale}>{item.title}</RelatedTitle>
                                    <RelatedDesc word={props.currentLocale}>{item.summary}</RelatedDesc>
                                    <RelatedDate>{moment(item.createdAt).format('YYYY. MM. DD')}</RelatedDate>
                                </RelatedTxt>
                            </RelatedItem>
                        )
                    })}
                </RelatedItemWrap>
            </RelatedContents>
            </> : <Loading></Loading>}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`

const Contents = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 80px 32px 0;
    box-sizing: border-box;

    
    @media screen and (max-width: 767px) {
        padding: 40px 28px 0;
    }
`

const DetailTitle = styled.div`
    padding-bottom: 20px;
    font-size: 24px;
    color: #222;
    line-height: 1.33;
    font-weight: bold;
    word-break : ${props => props.word === '/jp' ? 'normal' : 'keep-all'};

    @media screen and (max-width: 767px) {
        padding-bottom: 12px;
        font-size: 16px;
        line-height: 24px;
    }
`

const DetailInfo = styled.div`
    display: flex;
    color: #aaa;
    font-size: 14px;

    @media screen and (max-width: 767px) {
        justify-content: space-between;
        font-size: 12px;
    }
`

const DetailDate = styled.div`
    padding-right: 39px;
    
    @media screen and (max-width: 767px) {
        padding-right: 0;
    }
`
const DetailViews = styled.div`
`

const DetailContents = styled.div`
    padding: 60px 0 100px;

    @media screen and (max-width: 767px) {
        padding: 28px 0 80px;
`

const SlideWrap = styled.div`
    position: relative;
    width: 100%;

    .slick-slider {
        overflow: hidden;
    }

    .slick-prev {
        left: 52px;
        z-index: 10;
        width: 32px;
        height: 32px;
        background: url(${cardLeft}) no-repeat center / cover;
        opacity: .8;
        transition: all .3s;
        
        &:before {
            content: "";
        }
        &:hover {
            opacity: 1;
        }
    }
    .slick-next {
        right: 52px;
        z-index: 10;
        width: 32px;
        height: 32px;
        background: url(${cardRight}) no-repeat center / cover;
        opacity: .8;
        transition: all .3s;

        &:before {
            content: "";
        }
        &:hover {
            opacity: 1;
        }
    }

    .slick-slide {
        display: block;
        position: relative;
        text-align: center;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(255,255,255,.8);
            width: 100%;
            height: 100%;
            opacity: 1;
            transition: all 1s;
        }
    }
    .centerSlide {
        
        &:after {
            opacity: 0;
        }
    }

    @media screen and (max-width: 767px) {
        width: calc(100% + 32px);
        margin-left: -16px;

        .slick-prev {
            left: 0;
            width: 20px;
            height: 20px;
            opacity: 1;
        }

        .slick-next {
            right: 0;
            width: 20px;
            height: 20px;
            opacity: 1;
        }

        .slick-slide {
            &:after {
                content: none;
            }
        }
    }
`

const CardItem = styled.div`
    position: relative;
    width: 100%; 
    height: 0;
    padding-top: 100%;
    text-align: center;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 2.5%;
        width: 95%;
        height: 95%;
        background: url(${props => props.img}) no-repeat center;
        background-size: contain;
    }

    @media screen and (max-width: 767px) {
        &:after {
            top: 50%;
            left: 25px;
            width: calc(100% - 50px);
            height: calc(100% - 50px);
            transform: translateY(-50%);
            border-radius: 5px;
        }
    }
`

const CardNum = styled.div`
    position: absolute;
    bottom: -14px;
    left: 50%;
    padding: 3px 12px 5px;
    border-radius: 20px;
    transform: translateX(-50%);
    background-color: rgba(34, 34, 34, .4);
    color: #fff;
    font-size: 14px;
    font-weight: bold;

    @media screen and (max-width: 767px) {
        bottom: 0px;
        font-size: 10px;
    }
`

const DetailThumb = styled.img`
    width: 100%;
    max-width: 853px;
`

const DetailVideo = styled.div`
    position: relative;
    width: 100%;
    max-width: 853px;
    height: 480px;
    
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 4px;
        border: none;
    }

    @media screen and (max-width: 853px) {
        height: 0;
        padding-top: calc(480 / 853 * 100%);
    }

    @media screen and (max-width: 767px) {
        margin-left: -10px;
        width: calc(100% + 26px);
    }
`

const DetailConWrap = styled.div`
    width: 100%;
    margin: 60px 0;
`

const DetailDesc = styled.div`
    color: #222;
    font-size: 16px;
    line-height: 1.5;
    word-break : ${props => props.word === '/jp' ? 'normal' : 'keep-all'};
    
    p {
        color: #222;
        font-size: 16px;
        line-height: 1.5;
        word-break : ${props => props.word === '/jp' ? 'normal' : 'keep-all'};
        margin: 0;
    }
    img {
        max-width: 853px;
    }


    @media screen and (max-width: 767px) {
        padding-top: 24px;
        margin: 0;
        font-size: 14px;
        line-height: 22px;

        p {
            font-size: 14px;
            line-height: 22px;
        }
        img {
            width: 100%;
        }
    }
`
const DetailImg = styled.img`
    max-width: 853px;

    @media screen and (max-width: 853px) {
        width: 100%;
    }
`
const DetailImgCaption = styled.p`
    font-size: 14px;
    color: #666;
`
const ItemListWrap = styled.div`
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 160px;

    @media screen and (max-width: 1199px) {
        padding: 0 16px;
    }

    @media screen and (max-width: 767px) {
        margin-bottom: 60px;
    }
`
const ItemList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 0;
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;

    @media screen and (max-width: 767px) {
        padding: 17px 4px;
    }
`

const ListWrap = styled.a`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 255px;

    @media screen and (max-width: 767px) {
        min-width: auto;
    }
`

const EmptyData = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 255px;

    div:last-child {
        color: gray;
        font-weight: 400;
    }

    @media screen and (max-width: 767px) {
        min-width: auto;
    }
`

const EmptyDataRight = styled(EmptyData)`
    justify-content: flex-end;
`

const ListWrapRight = styled(ListWrap)`
    text-align: right;
    justify-content: flex-end;
`

const ListArrow = styled.img`
    width: 20px;
    height: 16px;
    margin-right: 25px;

    @media screen and (max-width: 767px) {
        margin-right: 33px;    
    }
`
const ListRightArrow = styled(ListArrow)`
    margin-left: 25px;
    margin-right: 0;

    @media screen and (max-width: 767px) {
        margin-left: 33px;    
    }
`

const ListTxt = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`

const ListTxtRight = styled(ListTxt)`
    align-items: flex-end;
`

const Order = styled.div`
    padding-bottom: 7px;
    font-size: 12px;
    color: #8f0a7c;
    font-weight: bold;


    @media screen and (max-width: 767px) {
        padding-bottom: 2px;
        // padding-top: 2px;
        color: #aaa;
    }
`

const ListTitle = styled.div`
    max-width: 210px;
    font-size: 14px;
    color: #222;
    font-weight: 500;
    line-height: 1.57;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    word-break : ${props => props.word === '/jp' ? 'normal' : 'keep-all'};

    @media screen and (max-width: 767px) {
        display: none;
    }
`

const ListCenter = styled.a`
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 100px;
    border-right: 1px solid #aaa;
    border-left: 1px solid #aaa;

    @media screen and (max-width: 870px) {
        padding: 0 60px;
    }
    @media screen and (max-width: 767px) {
        height: 17px;
        padding: 0 34px 3px;
    }
`

const ListIco = styled.img`
    width: 20px;
    margin-right: 12px;

    @media screen and (max-width: 767px) {
        display: none;
    }
`

const ListCenterTxt = styled.div`
    // padding-top: 2px;
    font-size: 16px;
    color: #8f0a7c;
    font-weight: bold;

    @media screen and (max-width: 767px) {
        color: #aaa;
        font-size: 12px;
        padding-bottom: 2px;
    }
`

const RelatedContents = styled.div`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 32px 160px;
    box-sizing: border-box;

    @media screen and (max-width: 767px) {
        padding: 0 16px 100px; 
`

const RelatedTop = styled.div`
    font-size: 20px;
    color: #222;
    font-weight: bold;

    @media screen and (max-width: 767px) {
        padding-left: 16px;
        font-size: 16px;
    }
`

const RelatedItemWrap = styled.div`
    min-height: 385px;
`

const RelatedItem = styled.a`
    display: block;
    float: left;
    margin-top: 23px;
    max-width: 350px;
    margin-right: 43px;

    &:last-child {
        margin-right: 0;

        @media screen and (max-width: 1200px) {
            margin-bottom: 50px;
        }
    }

    @media screen and (max-width: 767px) {
        float: none;
        max-width: 100%;
        margin-top: 24px;
        margin-right: 0;
    }
`

const RelatedThumb = styled.div`
    width: 350px;
    height: 197px;
    background: url(${props => props.img}) no-repeat center;
    ${props => props.img == null ? 'background-color: #e6e6e6' : null};
    background-size: contain;
    background-color: #e6e6e6;
    
    @media screen and (max-width: 767px) {
        width: 100%;
        height: 0;
        padding-top: calc( 193 / 343 * 100%);
    }

`

const RelatedTxt = styled.div`
    padding: 21px 10px 0 18px;

    @media screen and (max-width: 767px) {
        padding: 20px 10px 0 12px;
    }
`

const RelatedTitle = styled.div`
    font-size: 16px;
    color: #393939;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    word-break : ${props => props.word === '/jp' ? 'normal' : 'keep-all'};

    @media screen and (max-width: 767px) {
        font-size: 14px;
        color: #222;
        line0height: 22px;
    }
`
const RelatedDesc = styled.div`
    margin: 12px 0 20px;
    font-size: 14px;
    color: #393939;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; 
    -webkit-box-orient: vertical;
    word-break : ${props => props.word === '/jp' ? 'normal' : 'keep-all'};

    @media screen and (max-width: 767px) {
        margin: 16px 0;
        font-size: 12px;
        color: #222;
        line-height: 18px;
        -webkit-line-clamp: 3;
    } 
`

const RelatedDate = styled.div`
    font-size: 12px;
    color: #aaa;
    font-weight: bold;
`

const Loading = styled.div`
    width: 100%;
    height: 100vh;
    background: #fff;
`

export default withRouter(MediaDetail)