import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Select, Collapse, Drawer } from 'antd'
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons'
import CloseIco from '../images/vr/close_btn.png'

const Menubar = ({ showMenu, data, space, room, category, currentLocale, onCloseMenu, ...props }) => {
    const [cate, setCate] = useState('');
    const [isMobile, setIsMobile] = useState()

    useEffect(() => {
        console.log(currentLocale);
        // resize
        const resizeHandle = () => {
            if (typeof window !== 'undefined') {
                const winWidth = window.innerWidth;
                if (winWidth <= 900) setIsMobile(true)
                else setIsMobile(false)
            }
        }

        resizeHandle();
        
        window.addEventListener('resize', resizeHandle)
        return () => window.removeEventListener('resize', resizeHandle)
    }, [])

    useEffect(() => {
        if(currentLocale === '/en') {
            setCate('All');
        } else if(currentLocale === '/jp') {
            setCate('全体');
        } else {
            setCate('전체');
        }
    },[])


    const onClickItems = (value, local) => {
        // 클릭시 vr로 이동
        let vr = document.getElementById('vr').contentWindow || document.getElementById('vr').contentDocument;
        if(props.location.pathname === local) {
            vr.window[value]();
        } else {
            window.location.href = `${local}?item=${value}`;
        }
    }

    return (
        <Wrapper>
            <DrawerMenu
            placement="right"
            closable={false}
            onClose={onCloseMenu}
            visible={showMenu}
            width={isMobile ? '448px' : '672px'}
            contentWrapperStyle={{boxShadow:'none'}}
            drawerStyle={{width: isMobile ? '400px' : '600px', marginLeft: 'auto'}}>
                <MenuContents>
                    <CloseBtn src={CloseIco} onClick={onCloseMenu} alt='close button' />
                    <MenuTop>
                        <TopTxt>APPLICATIONS</TopTxt>
                        <TopSelectWrap>
                            <TopSelect defaultValue={cate} suffixIcon={ <CaretDownOutlinedIco />} dropdownClassName={'selectCategory'} onChange={(e) => setCate(e)}>
                                {category.map((item, idx) => {
                                    return (
                                        <Select.Option key={`cate_${idx}`} value={item}>{item}</Select.Option>
                                    )
                                })}
                            </TopSelect>
                        </TopSelectWrap>
                    </MenuTop>

                    <ItemList>
                        {room &&
                        <Collapse expandIconPosition={'right'} defaultActiveKey={room} expandIcon={() => <DownOutlined />}>
                            {data[space]?.map(item => {
                                let selected = item.items.filter(a => a.detail.type.find(b => b == cate))
                                if (cate == 'All' || cate == '전체' || cate == '全体') selected = item.items;

                                return (
                                    <Collapse.Panel 
                                    header={item.name} 
                                    key={item.key} 
                                    className={room == item.key ? 'activeRoom_collapse' : null}
                                    >
                                    {selected.map((el, idx) => {
                                        return (
                                            <ItemBox 
                                            onClick={() => {
                                                onCloseMenu();
                                                onClickItems(el.url, item.url);
                                            }} 
                                            key={`${item.key}_${idx}`}>
                                                <ItemImg src={el.detail.img} />
                                                <ItemName>{el.name}</ItemName>
                                            </ItemBox>
                                        )})
                                    }
                                    </Collapse.Panel>
                                )})
                            }
                        </Collapse>}
                    </ItemList>
                </MenuContents>
            </DrawerMenu>
        </Wrapper>
    )
}

const Wrapper = styled.div`
`

const DrawerMenu = styled(Drawer)`
    .ant-drawer-content {
        background-color: transparent;
    }
    .ant-drawer-body {
        padding: 0;
    }
`

const MenuContents = styled.div`
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background-color: rgba(34, 34, 34, 0.96);
    box-shadow: -6px 0 16px -8px rgb(0 0 0 / 8%), -9px 0 28px 0 rgb(0 0 0 / 5%), -12px 0 48px 16px rgb(0 0 0 / 3%);

    &::-webkit-scrollbar {
        width: 14px;
        background-color: rgba(34, 34, 34, 1);
    }
    &::-webkit-scrollbar-thumb {
        height: 10%;
        background-color: rgba(255,255,255,0.4);
        border-radius: 8px;   
        border: 4px solid rgba(34, 34, 34, 1);
    }

    @media screen and (max-width: 900px) and (orientation: landscape) {
        &::-webkit-scrollbar {
            width: 10px;
            background-color: rgba(34, 34, 34, 1);
        }
        &::-webkit-scrollbar-thumb {
            height: 10%;
            background-color: rgba(255,255,255,0.4);
            border-radius: 5px;   
            border: 3px solid rgba(34, 34, 34, 1);
        }
    }

`

const CloseBtn = styled.img`
    width: 36px;
    height: 36px;
    float: right;
    margin: 22px 40px 0;
    cursor: pointer;
    
    @media screen and (max-width: 900px) and (orientation: landscape) {
        width: 24px;
        height: 24px;
        margin: 10px 28px 0;
    }
`

const MenuTop = styled.div`
    clear: both;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 32px;

    @media screen and (max-width: 900px) and (orientation: landscape) {
        padding: 15px 28px;
    }
`

const TopTxt = styled.div`
    color: #fff;
    font-size: 26px;
    font-weight: bold;
    margin-right: 5%;

    @media screen and (max-width: 900px) and (orientation: landscape) {
        font-size: 20px;
    }
`

const TopSelectWrap = styled.div`
    width: 280px;

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        height: 45px;
        align-items: center;
        font-size: 18px;
        font-weight: bold;
        color: #222;
    }
    .ant-select-arrow {
        width: 20px;
        height: 20px;
        margin-top: -9px;
    }
    .ant-select-arrow .anticon:not(.ant-select-suffix) {
        pointer-events: none;
    }

    @media screen and (max-width: 900px) and (orientation: landscape) {
        width: 100%;
        max-width: 167px;

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            height: 36px;
            font-size: 12px;
        }
        .ant-select-arrow {
            width: 12px;
            margin-top: -6px;
        }
    }
`

const TopSelect = styled(Select)`
    width: 100%;
`

const CaretDownOutlinedIco = styled(CaretDownOutlined)`
    font-size: 20px;
    color: #222;

    @media screen and (max-width: 900px) and (orientation: landscape) {
        font-size: 12px;
    }
`

const ItemList = styled.div`
    width: 100%;
    padding: 0 32px;

    .ant-collapse {
        background-color: transparent;
        border-width: 0;
        color: #fff;
    }

    // .activeRoom_collapse {
    //     background-color: rgba(250,250,250,.04);
    //     border-radius: 8px;
    // }
    .ant-collapse-item-active {
        background-color: rgba(250,250,250,.04);
        border-radius: 8px;
    }

    .ant-collapse .ant-collapse-item .ant-collapse-header {
        width: 100%;
        color: #aaa;
        font-size: 24px;
        font-weight: bold;

        span {
            font-size: 26px;
            opacity: .7;
        }

        &:hover {
            color: #fff;

            span {
                opacity: 1;
            }
        }
    }

    .ant-collapse-item.activeRoom_collapse .ant-collapse-header {
        color: #fff;
    }

    .ant-collapse > .ant-collapse-item {
        border-width: 0;
        margin: 8px 0;
        border-radius: 8px
    }

    .ant-collapse-content {
        background-color: transparent;
        border-width: 0;
    }
    .ant-collapse-content > .ant-collapse-content-box {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    .ant-collapse > .ant-collapse-item-active > .ant-collapse-header .ant-collapse-arrow svg {
        transform: rotate(180deg);
    }


    @media screen and (max-width: 900px) and (orientation: landscape) {
        padding: 0 26px;

        .ant-collapse .ant-collapse-item .ant-collapse-header {
            font-size: 16px;
            span {
                font-size: 18px;
            }
        }
    }
`

const ItemBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(33.3% - 19px);
    margin-right: 28px;
    margin-bottom: 30px;
    cursor: pointer;

    &:nth-child(3n) {
        margin-right: 0;
    }

    &:hover img {
        transform: scale(1.1);
        transition: all .6s;
    }
`

const ItemImg = styled.img`
    width: 140px;
    transition: all .6s;

    @media screen and (max-width: 900px) and (orientation: landscape) {
        width: 100px;
    }
`

const ItemName = styled.div`
    font-size: 16px;
    color: #fff;
    font-weight: 500;

    @media screen and (max-width: 900px) and (orientation: landscape) {
        font-size: 12px;
        text-align: center;
    }
`


export default withRouter(Menubar)