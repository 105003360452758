import styled from 'styled-components';
import React ,{ useState, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import MediaList from '../components/MediaList'
import { Pagination } from 'antd'
import Tabs from '../components/Tabs'
import Banner from '../components/Banner'
import { getMediaList } from '../libs/media'
import { Fade } from 'react-reveal';
import Head from '../components/Head'
import { useTranslation } from 'react-i18next'

const Media = ({ ...props }) => {
    const { t } = useTranslation('media')

    const tabItems = [{
        url: `${props.currentLocale}/media`, 
        title: t('category', { returnObjects: true })[0]
    }, {
        url: `${props.currentLocale}/media/social`, 
        title: t('category', { returnObjects: true })[1]
    }, {
        url: `${props.currentLocale}/media/influencer`, 
        title: t('category', { returnObjects: true })[2]
    }, {
        url: `${props.currentLocale}/media/news`, 
        title: t('category', { returnObjects: true })[3]
    }]

    const [currentPage, setCurrentPage] = useState(1)
    const [mediaList, setMediaList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    const ChangePage = (e) => {
        setCurrentPage(e);
        // window.location.href = `/media?page=${e}`
        window.scrollTo({top: 0, behavior: 'smooth'})
    }

    useEffect(() => {
        if (props.location.search.indexOf('page') !== -1) {
            setCurrentPage(Number(props.location.search.slice(6)))
        }
    }, [])

    useEffect(() => {
        const callList = async () => {
            let category;
            if (props.location.pathname == `${props.currentLocale}/media`) category = 'all'
            if (props.location.pathname == `${props.currentLocale}/media/influencer`) category = 'influencer'
            if (props.location.pathname == `${props.currentLocale}/media/social`) category = 'social'
            if (props.location.pathname == `${props.currentLocale}/media/news`) category = 'news';

            const params = {
                category: category ? category : 'all',
                offset: 10 * (currentPage - 1),
                limit: 10
            }
            try {
                const result = await getMediaList(params, t('api.list'));
                if (result.status === 200) {
                    await setMediaList(result.data.rows)
                    await setTotalCount(result.data.count)
                    
                }
            } catch (err) {
                
            }
            
        }
        callList();
    }, [currentPage])

    return (
        <Wrapper>
            {props.location.pathname === `${props.currentLocale}/media` && 
                <Head
                title={t('header.all.title')}
                description={t('header.all.desc')}
                keyword={t('header.all.keyword')}
                lang={props.currentLocale} />
            }
            {props.location.pathname === `${props.currentLocale}/media/influencer` && 
                <Head
                title={t('header.influencer.title')}
                description={t('header.influencer.desc')}
                keyword={t('header.influencer.keyword')}
                lang={props.currentLocale} />
            }
            {props.location.pathname === `${props.currentLocale}/media/social` && 
                <Head
                title={t('header.social.title')}
                description={t('header.social.desc')}
                keyword={t('header.social.keyword')}
                lang={props.currentLocale} />
            }
            {props.location.pathname === `${props.currentLocale}/media/news` && 
                <Head
                title={t('header.news.title')}
                description={t('header.news.desc')}
                keyword={t('header.news.keyword')}
                lang={props.currentLocale} />
            }
            <Banner type='media' />

            <Contents>
                <Tabs {...props} tabItems={tabItems} type={'media'} />
                <AppList>
                    {mediaList.map((item, idx) => {
                        const content = JSON.parse(item.content)
                        let typeStr = ''
                        switch (content.type) {
                            case 'article':
                                typeStr = t('mediaType', { returnObjects: true })[0]
                                break;
                            case 'card':
                                typeStr = t('mediaType', { returnObjects: true })[1]
                                break;
                            case 'video':
                                typeStr = t('mediaType', { returnObjects: true })[2]
                                break;
                        }
                        
                        return (
                            <AppWarp key={String(idx)}>
                                <Fade duration={1100} delay={0} bottom> 
                                    <MediaList {...props} label={typeStr} data={item} props={props} />
                                </Fade>
                            </AppWarp>
                        )
                    })}
                </AppList>
                <Fade duration={1100} delay={1000} bottom> 
                    <PaginationWrap>
                        <Pagination 
                        total={totalCount}
                        current={currentPage}
                        itemRender={(page, type, origin) => {
                            if (type =='page') {
                                return <Link to={`${props.location.pathname}?page=${page}`}>{page}</Link>
                            } else {
                                return <Link to={`${props.location.pathname}?page=${page}`}>{origin}</Link>
                            }
                        }}
                        onChange={(e) => ChangePage(e)} />
                    </PaginationWrap>
                </Fade>
            </Contents>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
`

const EmptyTab = styled.div`
    width: 100%;
    height: 60px;
`

const Contents = styled.div`
    width: 100%;
    max-width: 1200px;
    min-height: 800px;
    margin: 0 auto;
    margin-bottom: 80px;
`

const AppList = styled.ul`
    width: 100%;
    padding-top: 10px;

    @media screen and (max-width: 1199px) {
        padding: 0 15px;
    }
`

const AppWarp = styled.li`
    padding-bottom: 30px;
`

const PaginationWrap = styled.div`
    width: 100%;
    text-align: center;
    padding-top: 60px;

    .ant-pagination-options {
        display: none;
    }
    
    .ant-pagination-item {
        width: 38px;
        height: 38px;
        margin: 0 4px;
        border-width: 0;

        a {
            width: 38px;
            height: 38px;
            padding: 5px 0;
            text-align: center;
            color: #393939;
            border-radius: 20px;
        }
    }
    .ant-pagination-item-active {
        a {
            background-color: #222;
            color: #fff;
        }
    }

    .ant-pagination-item-link {
        border-width: 0;
    }
`


export default withRouter(Media)