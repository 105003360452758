

const Media = {
    header: {
        all: {
            title: 'violeds | MEDIA - 전체',
            desc: '이제는 밀접해져 일상 속 필수가 된 violeds. 더욱 가까워진 violeds를 다양한 채널을 통해 만나보세요.',
            keyword: 'violeds, Violeds, 바이오레즈, 서울바이오시스, 미디어, media'
        },
        sterilization: {
            title: 'violeds | MEDIA - 인플루언서',
            desc: '이제는 밀접해져 일상 속 필수가 된 violeds. 더욱 가까워진 violeds를 다양한 채널을 통해 만나보세요.',
            keyword: 'violeds, Violeds, 바이오레즈, 서울바이오시스, 미디어, media, 인플루언서, influencer'
        },
        deodorization: {
            title: 'violeds | MEDIA - 소셜콘텐츠',
            desc: '이제는 밀접해져 일상 속 필수가 된 violeds. 더욱 가까워진 violeds를 다양한 채널을 통해 만나보세요.',
            keyword: 'violeds, Violeds, 바이오레즈, 서울바이오시스, 미디어, media, 소셜콘텐츠, social'
        },
        insectAttraction: {
            title: 'violeds | MEDIA - 보도자료',
            desc: '이제는 밀접해져 일상 속 필수가 된 violeds. 더욱 가까워진 violeds를 다양한 채널을 통해 만나보세요.',
            keyword: 'violeds, Violeds, 바이오레즈, 서울바이오시스, 미디어, media, 보도자료, news'
        }
    },
    category: ['전체', '소셜콘텐츠', '인플루언서', '보도자료'],
    mediaType: ['아티클', '카드뉴스', '비디오'],
    list: {
        next: '다음',
        prev: '이전',
        golist: '목록으로',
        noNext: '다음글이 없습니다.',
        noPrev: '이전글이 없습니다.',
        related: '연관 콘텐츠'
    },
    api: {
        list: '/media/list',
        detail: '/media',
        preNext: '/media/pre-next',
        related: '/media/relative'
    }
}

export default Media
