import React from "react";
import styled from 'styled-components'

const CompanyHistory = ({year, desc, idx, lang, isEnd}) => {
    
    const yearColor = ['#60dfb3', '#7bc0ce', '#93a3e6']


    return (
        <HisContent isEnd={isEnd}>
            <HistYear color={yearColor[(idx+3) % 3]}>{year}</HistYear>
            <HisDesc lang={lang ? true : false}>{desc}</HisDesc>
        </HisContent>
    )
}

const HisContent = styled.div`
    width : 320px;
    ${props=>props.isEnd && 'margin-right: 180px;'}
    @media all and (max-width:1000px) {
        width: 220px;
    }
`

const HistYear = styled.div`
    margin-bottom : 19px;
    font-size: 28px;
    font-weight: bold;
    line-height: 0.86;
    color: ${props=>props.color};
    @media all and (max-width:1000px) {
        font-size: 24px;
        line-height: 1.33;
    }
`

const HisDesc = styled.div`
    font-size: ${props=>props.lang ? '18px' : '24px'};
    font-weight: 500;
    line-height: 1.5;
    color: #222;
    word-break : keep-all;
    white-space: pre-line;

    @media all and (max-width:1000px) {
        margin-top : 16px;
        font-size: 16px;
    }
`
export default CompanyHistory