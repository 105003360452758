import styled, { keyframes } from 'styled-components'
import React, { useState, useEffect } from 'react'
import RightArrow from '../images/main/applications/arrow_next.png'
import RightArrowActive from '../images/main/applications/arrow_next_active.png'
import LeftArrow from '../images/main/applications/arrow_prev.png'
import LeftArrowActive from '../images/main/applications/arrow_prev_active.png'
import ApplicationItems from '../data/ApplicationItems'
import { Fade } from 'react-reveal'
import { useTranslation } from 'react-i18next'

const RoundSlide = () => {
    const { t } = useTranslation('main')
    const alt = t('alt.home', { returnObjects: true })
    const [current, setCurrent] = useState(0)
    const [slideItems, setSlideItems] = useState(null)
    const [leftActive, setLeftActive] = useState(false);
    const [rightActive, setRightActive] = useState(false);

    useEffect(() => {
        const changeData = () => {
            let data = ApplicationItems.data;
            if (window.innerWidth > 768) {
                data = data.concat(ApplicationItems.data.slice(8, 16))
                
            }
            setSlideItems(data)
        }
        changeData();
        window.addEventListener('resize', changeData)
    }, [])

    useEffect(() => {
        const RoundSlideFunc = () => {

            let bigCircle = 150;
            let middleCircle = 70;
            let thirdCircle = 70;
            let lastCircle = 70;

            if (window.innerWidth < 1800) {
                bigCircle = 120;
                middleCircle = 60;
                thirdCircle = 60;
                lastCircle = 60;
            }

            if (window.innerWidth < 1450) {
                bigCircle = 80;
                middleCircle = 40;
                thirdCircle = 40;
                lastCircle = 40;
            }

            if (window.innerWidth < 767) {
                bigCircle = 70;
                middleCircle = 30;
                thirdCircle = 30;
                lastCircle = 30;
            }

            function startSetup( sliderSize, slideSize, animationDuration, autoplayInterval ) {
        
                this.sliderSize        = parseFloat( sliderSize )/100;
                this.slideSize         = parseFloat( slideSize )/100;
                this.animationDuration = parseFloat( animationDuration );
                this.autoplayInterval  = parseFloat( autoplayInterval );
        
            };
        
            function Slider( newSlider, sliderSize, slideSize, animationDuration, autoplayInterval ) {
        
                this.startSetup           = new startSetup( sliderSize, slideSize, animationDuration, autoplayInterval );
        
                this.wrapper              = newSlider.querySelector( '.wrapper' );
        
                this.slides               = newSlider.querySelectorAll( '.circular-slider .wrapper .slides-holder .slides-holder__item' );
        
                this.slidesSize           = 0;
        
                this.descriptionsHolder   = newSlider.querySelector( '.circular-slider .wrapper .descriptions' );
        
                this.descriptions         = newSlider.querySelectorAll( '.circular-slider .wrapper .descriptions .descriptions__item' );
        
                this.slidesHolder         = newSlider.querySelector( '.circular-slider .wrapper .slides-holder' );
        
                this.btnLeft              = newSlider.querySelector( '.circular-slider .wrapper .controls .controls__left' );
        
                this.btnRight             = newSlider.querySelector( '.circular-slider .wrapper .controls .controls__right' );
        
                this.currentAngle         = 0;
        
                this.stepAngle            = 2*Math.PI/newSlider.querySelectorAll( '.circular-slider .wrapper .slides-holder .slides-holder__item' ).length;
        
                this.currentSlide         = 0;
        
                this.slidesHolder.style.transitionDuration = this.startSetup.animationDuration + 'ms';
                this.onResize();
                this.setAutoplay();
                this.setNav();
                this.addStyle();
            };
        
            Slider.prototype.onResize = function() {

                let radius,
                    w = this.wrapper.parentNode.getBoundingClientRect().width,
                    h = this.wrapper.parentNode.getBoundingClientRect().height;
        
                2*h <= w ? radius = h*this.startSetup.sliderSize
                         : radius = ( w/2 )*this.startSetup.sliderSize;
        
                this.setSize( Math.round( radius ) );
            };
        
            Slider.prototype.setSize = function( radius ) {
                // console.log(window.innerWidth, window.innerHeight, radius);

                this.wrapper.style.width  = radius*3.2 + 'px';
                this.wrapper.style.height = radius/1.4 + 'px';
                let r = window.innerWidth*1.8;
                
                if (window.innerWidth > 1060 && window.innerHeight < 800) {
                    this.wrapper.style.height = radius/1.3 + 'px';
                }
                if (window.innerWidth < 1060) {
                    this.wrapper.style.height = '798px';
                    // this.wrapper.style.height = radius/1.3 + 'px';
                }
                if (window.innerWidth < 767) {
                    this.wrapper.style.width  = radius*2.3 + 'px';
                    this.wrapper.style.height = radius/2.2 + 'px';
                    r = window.innerWidth*2.5
                }
                if (window.innerWidth < 500) {
                    this.wrapper.style.height = radius/1.3 + 'px';
                    r = window.innerWidth*2.7
                }
                this.slidesHolder.style.width = this.slidesHolder.style.height = r + 'px';
                this.slidesRepositioning( r/2 );

                this.slidesSize = Math.min( 3*radius*this.startSetup.slideSize, this.stepAngle*radius*( 1 - this.startSetup.slideSize ) - 50 );
                this.descriptionsHolder.style.fontSize = window.innerHeight < window.innerWidth ? '1.2vh' : '1.2vw';
                                                                                        
                // 원래 원 사이즈
                for( let i = 0; i < this.slides.length; i++ ) {
                    this.slides[i].style.width = this.slides[i].style.height = this.slidesSize + lastCircle + 'px';
                };
        
            };
        
            Slider.prototype.slidesRepositioning = function( r ) {
        
                for( let i = 0; i < this.slides.length; i++ ) {
        
                    let x = r*Math.cos( this.stepAngle*i - Math.PI/2 ),
                        y = r*Math.sin( this.stepAngle*i - Math.PI/2 );
                    this.slides[i].style.transform = 'translate( ' + x  + 'px, ' + y + 'px ) rotate( ' + this.stepAngle*180/Math.PI*i + 'deg )';
        
                };
        
            };
        
            Slider.prototype.rotate = function( multiplier ) {
                let _this = this;
        
                this.removeStyle();
                this.resetNavs();
        
                if( this.currentSlide === this.slides.length - 1  && multiplier === -1 ) {
        
                    this.slidesHolder.style.transform     = 'rotate( -360deg )';
                    this.currentSlide = this.currentAngle = 0;
                    this.addStyle();
        
                    setTimeout( function(){
        
                        _this.slidesHolder.style.transitionDuration = 0 + 's';
                        _this.slidesHolder.style.transform          = 'rotate( ' + _this.currentAngle + 'deg )';
                        setTimeout( function() { _this.slidesHolder.style.transitionDuration = _this.startSetup.animationDuration + 'ms'; }, 20 );
        
                    }, this.startSetup.animationDuration );
        
                } else if ( this.currentSlide === 0 && multiplier === 1 ) {
        
                    this.slidesHolder.style.transform = 'rotate( ' + this.stepAngle*180/Math.PI + 'deg )';
                    this.currentSlide = _this.slides.length - 1;
                    this.currentAngle = -( 2*Math.PI - _this.stepAngle )*180/Math.PI;
                    this.addStyle();
        
                    setTimeout( function(){
        
                        _this.slidesHolder.style.transitionDuration = 0 + 's';
                        _this.slidesHolder.style.transform = 'rotate( ' + _this.currentAngle + 'deg )';
                        setTimeout( function() { _this.slidesHolder.style.transitionDuration = _this.startSetup.animationDuration + 'ms'; }, 20 );
        
                    }, this.startSetup.animationDuration );
        
                } else {
        
                    this.currentSlide -= multiplier;
                    this.currentAngle += ( this.stepAngle*180/Math.PI )*multiplier;
                    this.slidesHolder.style.transform = 'rotate( ' + this.currentAngle + 'deg )';
                    this.addStyle();
        
                };

                setCurrent(this.currentSlide)
        
            };
        
            Slider.prototype.setNav = function() {
        
                let _this              = this;
                _this.btnLeft.onclick  = function() { _this.rotate(1) };
                _this.btnRight.onclick = function() { _this.rotate(-1) };
        
            };
        
            Slider.prototype.disableNav = function() {
        
                this.btnLeft.onclick  = null;
                this.btnRight.onclick = null;
        
            };
        
            Slider.prototype.setAutoplay = function() {
                let _this     = this;
                this.autoplay = setInterval( function() { _this.rotate(-1) }, _this.startSetup.autoplayInterval + 20 ); 
            };
        
            Slider.prototype.removeStyle = function() {
        
                let x = this.currentSlide;
                // console.log('remove:', x)
        
                this.descriptions[x].classList.remove( 'descriptions__item_visible' );
                this.slides[x].classList.remove( 'slides-holder__item_active' );
                this.slides[x].style.height = this.slides[x].style.width = this.slidesSize + 90 + 'px';
        
                // 원래 사이즈로 복원
                // 왼쪽 원
                if (x == 0) {
                    this.slides[this.slides.length - 2].style.height = this.slides[this.slides.length - 2].style.width = this.slidesSize + lastCircle + 'px';
                } else if (x == 1) {
                    this.slides[this.slides.length - 1].style.height = this.slides[this.slides.length - 1].style.width = this.slidesSize + lastCircle + 'px';
                } else {
                    this.slides[x-2].style.height = this.slides[x-2].style.width = this.slidesSize + lastCircle + 'px';
                }
        
                // 오른쪽 원
                if (x == this.slides.length - 1) {
                    this.slides[1].style.height = this.slides[1].style.width = this.slidesSize + lastCircle + 'px';
                    return;
                }
                if (x == this.slides.length - 2) {
                    this.slides[0].style.height = this.slides[0].style.width = this.slidesSize + lastCircle + 'px';
                    return;
                }
        
                this.slides[x+2].style.height = this.slides[x+2].style.width = this.slidesSize + lastCircle + 'px';
            };
        
            Slider.prototype.addStyle = function() {
        
                let x = this.currentSlide;
                // console.log('add:', x, this.slides.length )
        
                this.descriptions[x].classList.add( 'descriptions__item_visible' );
                this.slides[x].classList.add( 'slides-holder__item_active' );
        
                // 현재 보여지는 가운데 원 크기 조절
                this.slides[x].style.height = this.slides[x].style.width = this.slidesSize + bigCircle + 'px';
                
                // 양 옆의 원 사이즈 조절
                // 왼쪽 원
                if (x == 0) {
                    this.slides[this.slides.length - 1].style.height = this.slides[this.slides.length - 1].style.width = this.slidesSize + middleCircle + 'px';
                    this.slides[this.slides.length - 2].style.height = this.slides[this.slides.length - 2].style.width = this.slidesSize + thirdCircle + 'px';
                } else if (x == 1) {
                    this.slides[0].style.height = this.slides[0].style.width = this.slidesSize + middleCircle + 'px';
                    this.slides[this.slides.length - 1].style.height = this.slides[this.slides.length - 1].style.width = this.slidesSize + thirdCircle + 'px';
                } else {
                    this.slides[x-1].style.height = this.slides[x-1].style.width = this.slidesSize + middleCircle + 'px';
                    this.slides[x-2].style.height = this.slides[x-2].style.width = this.slidesSize + thirdCircle + 'px';
                }
        
                // 오른쪽 원
                if (x == this.slides.length - 1) {
                    this.slides[0].style.height = this.slides[0].style.width = this.slidesSize + middleCircle + 'px';
                    this.slides[1].style.height = this.slides[1].style.width = this.slidesSize + thirdCircle + 'px';
                    return;
                }
                this.slides[x+1].style.height = this.slides[x+1].style.width = this.slidesSize + middleCircle + 'px';
                if (x == this.slides.length - 2) {
                    this.slides[0].style.height = this.slides[0].style.width = this.slidesSize + thirdCircle + 'px';
                    return
                };
                this.slides[x+2].style.height = this.slides[x+2].style.width = this.slidesSize + thirdCircle + 'px';
        
            };
        
            Slider.prototype.resetNavs = function() {
        
                let _this = this;
        
                this.disableNav();
                setTimeout( function(){ _this.setNav() }, this.startSetup.animationDuration + 20 );
                if ( this.autoplay != null ) {
                    clearInterval( this.autoplay );
                    this.setAutoplay();
                };
            };
        
            ///////////Init sliders/////////// 

            window.circularSlider = new Slider( document.querySelector( '.circular-slider' ), 140, 40, 800, 3700 );
        
            let sliders = [ window.circularSlider ];
            window.onresize = function() {

                bigCircle = 150;
                middleCircle = 70;
                thirdCircle = 70;
                lastCircle = 70;
    
                if (window.innerWidth < 1800) {
                    bigCircle = 120;
                    middleCircle = 60;
                    thirdCircle = 60;
                    lastCircle = 60;
                }
    
                if (window.innerWidth < 1450) {
                    bigCircle = 80;
                    middleCircle = 40;
                    thirdCircle = 40;
                    lastCircle = 40;
                }
    
                if (window.innerWidth < 767) {
                    bigCircle = 70;
                    middleCircle = 30;
                    thirdCircle = 30;
                    lastCircle = 30;
                }

                for ( let i = 0; i < sliders.length; i++ ){
        
                    sliders[i].resetNavs();
                    sliders[i].onResize();
        
                };
        
            };
        
        }

        setTimeout(() => {
            RoundSlideFunc()  
        }, 300)
    }, [])

    return (
        <Wrapper>
            <Fade bottom duration={1500}>
                {window?.innerWidth < 767 ? (
                    <Title>{t('applicationField.title.mo')}</Title>
                ) : (
                    <Title>{t('applicationField.title.pc')}</Title>
                )}
                
            </Fade>
            <div className="slider">
                <div className="circular-slider circular-slider-1">
                    <div className="wrapper">
                            
                        <div className="slides-holder">
                            {t('applicationField', { returnObjects: true }).items?.data.map((item, idx) => {
                                return (
                                    <div className="slides-holder__item" key={`round_${idx}`}>
                                        {
                                            current == idx ? <img src={item.mainImg.on} alt={alt.application[item.keyword]} /> : <img src={item.mainImg.off} alt={alt.application[item.keyword]} />
                                        }
                                    </div>
                                )
                            })}
                        </div>
                        <div className="descriptions">

                            <div className="controls">
                                <div className="controls__left">
                                    <div className="icon-wrapper" onMouseEnter={()=>setLeftActive(true)} onMouseLeave={()=>setLeftActive(false)}><img src={leftActive ? LeftArrowActive : LeftArrow} alt="left arrow" /></div>
                                </div>
                                <div className="controls__right">
                                    <div className="icon-wrapper" onMouseEnter={()=>setRightActive(true)} onMouseLeave={()=>setRightActive(false)}><img src={rightActive ? RightArrowActive : RightArrow} alt="right arrow" /></div>
                                </div>
                            </div>
                            {t('applicationField', { returnObjects: true }).items?.data.map((item, idx) => {
                                return (
                                    <div className="descriptions__item" key={`desc_${idx}`}>
                                        <h3>{item.title}</h3>
                                        <p className="description">{item.desc}</p>
                                        <DeTailBtn href={item.goApplications}>{t('applicationField.buttonLabel')}</DeTailBtn>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>

        </Wrapper>
    )
}

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    padding-top: 140px;
    overflow:hidden;
    @media screen and (max-width: 1200px) {
        padding-top: 80px;
    }

    @media screen and (max-width: 767px) {
        padding-top: 0px;
    }
`

const Title = styled.h2`
    font-size: 40px;
    font-weight: 900;
    text-align: center;
    color: #fff;
    white-space: pre-line;
    @media screen and (max-width: 767px) {
        font-size: 34px;
    }
`

const NavKeyframes = keyframes`
    0%{
        background-position: 0%;
    }
    100%{
        background-position: 400%;
    }
`

const DeTailBtn = styled.a`
    position: relative;
    width: 152px;
    height: 42px;
    margin-top: 20px;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    border-radius: 21px;
    line-height: 42px;
    background: #333;
    outline: none;
    text-decoration: none;
    -webkit-transition: background 0.5s;
    transition: background 0.5s;

    &:hover {
        background: -webkit-linear-gradient(left, #9f0588, #ab86ff, #46ff98, #9f0588);
        background: linear-gradient(90deg, #9f0588, #ab86ff, #46ff98, #9f0588);
        background-size: 400%;
        z-index: 1;
        color: #fff;
        -webkit-animation: ${NavKeyframes} 8s linear infinite;
                animation: ${NavKeyframes} 8s linear infinite;
    }

    &::before {
        content: '';
        position: absolute;
        background: inherit;
        top: -5px;
        right: -5px;
        bottom: -5px;
        left: -5px;
        border-radius: 50px;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur stdDeviation="20" /></filter></svg>#filter');
        -webkit-filter: blur(20px);
                filter: blur(20px);
        opacity: 0;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
        -webkit-transition: opacity 0.5s;
        transition: opacity 0.5s;
    }

    &:hover::before {
        opacity: 1;
        -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
        z-index: -1;
    }
`

export default RoundSlide