import logo from '../images/common/footer_logo.png'
import inquiryImg from '../images/common/inquiry_icon.png'

export default {
    header: {
        gnb: {
            showroom: 'VIRTUAL SHOWROOM',
            about: 'ABOUT COMPANY',
            tech: 'TECHNOLOGY',
            applications: 'APPLICATIONS',
            media: 'MEDIA'
        },
        sideMenu: {
            review: 'Brand Review',
            tech: 'Technology',
            uv: 'UV Application Field',
            media: 'Media'
        },
        technology: { 
            desc: '서울바이오시스와 SETi사가 공동 개발한 UV LED 청정 기술로 물, 공기, 표면 등의\n살균 및 정화, 탈취를 위한 응용제품에 활용가능합니다.'
        },
        applications: {
            desc: 'Violeds의 만남은 앞으로도 계속됩니다.\n점점 뻗어나가는 Violeds의 적용사례를 만나보세요.'
        },
        media: {
            desc: '이제는 밀접해져 일상 속 필수가 된 violeds.\n더욱 가까워진 violeds를 다양한 채널을 통해 만나보세요.'
        }
    },
    footer: {
        social: {
            facebook: {
                href: 'https://www.facebook.com/violedsTechnologykorea',
                alt: 'facebook',              
            },
            youtube: {
                href: 'https://www.youtube.com/c/violedstechnology',
                alt: 'youtube',
            },
            linkedin: {
                href: 'https://www.linkedin.com/company/seoulviosys/',
                alt: 'linkedin',
            }
        },
        logo: {
            image: logo,
            href: '/',
            alt: 'Seoul Viosys'
        },
        site: {
            label: '관계사 사이트로 이동하기',
            viosys: {
                title: 'Seoul Viosys',
                href: 'http://www.seoulviosys.com/kr/'
            },
            seti: {
                title: 'SETi',
                href: 'http://www.s-et.com/en/'
            },
            semicon: {
                title: 'Seoul Semiconductor',
                href: 'http://www.seoulsemicon.com/kr'
            }
        },
        menu: [{
            link : 'http://www.seoulviosys.com/kr/company/contact/location/',
            text : '찾아오시는길'
        }, {
            link : 'http://www.seoulviosys.com/kr/company/contact/branch/',
            text : '해외법인 및 지사'
        }, {
            link : 'http://www.seoulviosys.com/kr/act/ask.asp',
            text : '고객문의'
        }, {
            link : '/policy/privacy',
            text : '개인정보취급방침'
        }, {
            link : 'http://www.seoulviosys.com/kr/technology/legal/',
            text : 'Patent'
        }]    
    },
    inquiry: {
        img: inquiryImg,
        href: 'http://www.seoulviosys.com/kr/support/contactus/',
        alt: '구매 문의 페이지로 이동'
    }
}