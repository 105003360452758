import axios from 'axios'
import baseUrl from './base.json'


const env = process.env.API_ENV || 'development';

export function postInquiry(data) {
    return axios({
        method: 'post',
        url: '/inquiry',
        baseURL: baseUrl[env].baseUrl,
        data: data
    })
}