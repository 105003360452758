// Images
import Feature_01 from '../../images/tech/tech_feature_01.png'
import Feature_02 from '../../images/tech/tech_feature_02.png'
import Feature_03 from '../../images/tech/tech_feature_03.png'
import Feature_04 from '../../images/tech/tech_feature_04.png'
import Feature_05 from '../../images/tech/tech_feature_05.png'
import Feature_06 from '../../images/tech/tech_feature_06.png'
import Feature_07 from '../../images/tech/tech_feature_07.png'
import Feature_08 from '../../images/tech/tech_feature_08.png'
import Feature_09 from '../../images/tech/tech_feature_09.png'
import Feature_10 from '../../images/tech/tech_feature_10.png'
import Feature_11 from '../../images/tech/tech_feature_11.png'
import Product_01 from '../../images/tech/tech_product_01.png'
import Product_02 from '../../images/tech/tech_product_02.png'
import Product_03 from '../../images/tech/tech_product_03.png'
import Product_04 from '../../images/tech/tech_product_04.png'
// import Product_05 from '../../images/tech/tech_product_05.png'
import Product_06 from '../../images/tech/tech_product_06.png'
import Product_07 from '../../images/tech/tech_product_07.png'
import Product_08 from '../../images/tech/tech_product_08.png'
import Product_09 from '../../images/tech/tech_product_09.png'
import Product_10 from '../../images/tech/tech_product_10.png'
import Product_11 from '../../images/tech/tech_product_11.png'
import Product_12 from '../../images/tech/tech_product_12.png'
import Product_13 from '../../images/tech/tech_product_13.png'
import Example_01 from '../../images/tech/tech_example_01.png'
// import Example_02 from '../../images/tech/tech_example_02.png'
import Example_03 from '../../images/tech/tech_example_03.png'
import Example_04 from '../../images/tech/tech_example_04.png'
import Example_05 from '../../images/tech/tech_example_05.png'
import Example_06 from '../../images/tech/tech_example_06.png'
import Example_07 from '../../images/tech/tech_example_07.png'
import Example_08 from '../../images/tech/tech_example_08.png'
import Example_09 from '../../images/tech/tech_example_09.png'
import Example_10 from '../../images/tech/tech_example_10.png'
import Example_11 from '../../images/tech/tech_example_11.png'
import Example_12 from '../../images/tech/tech_example_12.png'
import Example_13 from '../../images/tech/tech_example_13.png'
import Example_14 from '../../images/tech/tech_example_14.png'

// Data
export default {
    main: {
        tab: ['Sterilization', 'Deodorization', 'Insect Attraction', 'Curing'],
        head: [{
            title: 'violeds | TECHNOLOGY - Sterilization',
            desc: 'Developed in collaboration between Seoul Viosys and SETi, UV LED clean technology can be utilized in applied products for the sterilization, purification and deodorization of water, air and surfaces.',
            keyword: 'violeds, Violeds, violeds, Seoulvyosis, technology, technology, sterilization, 99.9% sterilization, toxic chemicals, long-life, low energy consumption, low voltage, air purifier, household appliances, electronics, water purifier, portable air purifier, refrigerator, surface sterilization'
        }, {
            title: 'violeds | TECHNOLOGY - Deodorization',
            desc: 'Developed in collaboration between Seoul Viosys and SETi, UV LED clean technology can be utilized in applied products for the sterilization, purification and deodorization of water, air and surfaces.',
            keyword: 'violeds, Violeds, violeds, Seoulvyosis, technology, technology, deodorization, photocatalysis, toxic gas removal, tobacco smoke removal, odor removal, air conditioner, air purifier, dish washer, steam closet'
        }, {
            title: 'violeds | TECHNOLOGY - Insect Attraction',
            desc: 'Developed in collaboration between Seoul Viosys and SETi, UV LED clean technology can be utilized in applied products for the sterilization, purification and deodorization of water, air and surfaces.',
            keyword: 'violeds, Violeds, violeds, Seoulvyosis, technology, technology, insect attraction, sterilization'
        }, {
            title: 'violeds | TECHNOLOGY - Curing',
            desc: 'Developed in collaboration between Seoul Viosys and SETi, UV LED clean technology can be utilized in applied products for the sterilization, purification and deodorization of water, air and surfaces.',
            keyword: 'violeds, Violeds, violeds, Seoulvyosis, technology, sterilization, 102% sterilization, toxic chemicals, long-life, low energy consumption, low voltage, air purifier, household appliances, electronics, water purifier, portable air purifier, refrigerator, surface sterilization'
        }]
    },
    sterilization: {
        category: 'Sterilization',
        header: 'Seoul Viosys <span style="font-weight: normal">utilizes the world\'s best-performing</span> UVC LED <span style="font-weight: normal">and non-hazardous</span> eco-friendly technology <span style="font-weight: normal">to provide surface sterilization</span> and water disinfection solutions.',
        headerMobile: 'Seoul Viosys <span style="font-weight: normal">utilizes the world\'s best-performing</span> UVC LED <span style="font-weight: normal">and non-hazardous</span> eco-friendly technology <span style="font-weight: normal">to provide surface sterilization</span> and water disinfection solutions.',
        section: [{
            title: 'Features',
            contents: [{
                alt: '99.9% Sterilization',
                image: Feature_01,
                title: '99.9% Sterilization',
                desc: 'Successfully sterilizes 99.9% of E. coli, an important indicator bacterium for contamination, and Staphylococcus aureus, the bacterium responsible for food poisoning.',
                descMobile: 'Successfully sterilizes 99.9% of E. coli, an important indicator bacterium for contamination, and Staphylococcus aureus, the bacterium responsible for food poisoning.'
            },{
                alt: 'Zero Toxic Chemicals',
                image: Feature_02,
                title: 'Zero Toxic Chemicals',
                desc: 'Violeds technology contains zero mercury and toxic chemicals, and can be safely used without any toxic effects to the human body.',
                descMobile: 'Violeds technology contains zero mercury and toxic chemicals, and can be safely used without any toxic effects to the human body.'
            },{
                alt: 'Long Lifetime of 50,000 hrs',
                image: Feature_03,
                title: 'Long Lifetime of 50,000 hrs',
                desc: 'Long lifetime of 50,000 hrs according to L50 standards allows for reduced replacement costs.',
                descMobile: 'Long lifetime of 50,000 hrs according to L50 standards allows for reduced replacement costs.'
            },{
                alt: 'Low Energy Consumption',
                image: Feature_04,
                title: 'Low Energy Consumption',
                desc: 'The UV LED\'s low energy consumption reduces energy costs.',
                descMobile: 'The UV LED\'s low energy consumption reduces energy costs.'
            }]
        }, {
            title: 'Product Family',
            caution: '* You can check the details when selecting a product.',
            readmore: 'View detail',
            contents: [{
                title: 'PKG',
                items: [{
                    alt: 'CA PKG(1chip)',
                    image: Product_01,
                    name: 'CA PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/en/product/new.asp'
                }, {
                    alt: 'AAP PKG(1chip)',
                    image: Product_02,
                    name: 'AAP PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/en/product/new.asp'
                }, {
                    alt: 'AAP PKG(4chip)',
                    image: Product_03,
                    name: 'AAP PKG',
                    desc: '(4chip)',
                    link: 'http://www.seoulviosys.com/en/product/new.asp'
                }]
            }, {
                title: 'Sterilization Module',
                items: [{
                    alt: 'Sterilization Module (Waterproof)',
                    image: Product_04,
                    name: 'Sterilization Module',
                    desc: '(Waterproof)',
                    link: 'http://www.seoulviosys.com/en/product/new.asp'
                }]
            }]
        }, {
            title: 'Application Case',
            caution: '* You can check the details when selecting a product.',
            readmore: 'View detail',
            contents: [{
                alt: 'Air Purifiers',
                image: Example_01,
                name: 'Air Purifiers',
                link: '/en/applications/sterilization?product=airpurifier#airpurifier',
                width: '83'
            }, {
                alt: 'Home Appliances',
                image: Example_13,
                name: 'Home Appliances',
                link: '/en/applications?product=homeAppliance#homeAppliance',
                width: '130'
            }, {
                alt: 'Water Purifiers',
                image: Example_03,
                name: 'Water Purifiers',
                link: '/en/applications/sterilization?product=waterPurifier#waterPurifier',
                width: '60'
            }, {
                alt: 'Portable Air Purifiers',
                image: Example_04,
                name: 'Portable Air Purifiers',
                link: '/en/applications/sterilization?product=portableAirpurifier#portableAirpurifier',
                width: '44'
            }, {
                alt: 'Air Conditioners',
                image: Example_07,
                name: 'Air Conditioners',
                link: '/en/applications/sterilization?product=airConditioner#airConditioner',
                width: '130'
            }, {
                alt: 'Disinfecting Surfaces',
                image: Example_06,
                name: 'Disinfecting Surfaces',
                link: '/en/applications/sterilization?product=disinfectingSurfaces#disinfectingSurfaces',
                width: '146'
            }]
        }]
    },
    deodorization: {
        category: 'Deodorization',
        header: 'Seoul Viosys <span style="font-weight: normal">utilizes UV LEDs with stable output, high reliability and </span>photocatalyst technology to provide a solution <span style="font-weight: normal">that is 8 times more efficient in removing </span>harmful gases than existing deodorization technologies.',
        headerMobile: 'Seoul Viosys <span style="font-weight: normal">utilizes UV LEDs with stable output, high reliability and </span>photocatalyst technology to provide a solution <span style="font-weight: normal">that is 8 times more efficient in removing </span>harmful gases than existing deodorization technologies.',
        section: [{
            title: 'Features',
            contents: [{
                alt: 'Outstanding removal of odors, VOCs and toxic chemicals',
                image: Feature_05,
                title: 'Outstanding removal of odors, VOCs and toxic chemicals',
                desc: 'Removes odors, VOCs*, and toxic chemicals to provide a pleasant and healthy environment.\n(*VOCs: Volatile Organic Compound materials cause negative health effects, such as sick building syndrome and respiratory disease)',
                descMobile: 'Removes odors, VOCs*, and toxic chemicals to provide a pleasant and healthy environment.\n(*VOCs: Volatile Organic Compound materials cause negative health effects, such as sick building syndrome and respiratory disease)'
            },{
                alt: 'Zero Toxic Chemicals',
                image: Feature_02,
                title: 'Zero Toxic Chemicals',
                desc: 'Violeds technology contains zero mercury and toxic chemicals, and can be safely used without any toxic effects to the human body.',
                descMobile: 'Violeds technology contains zero mercury and toxic chemicals, and can be safely used without any toxic effects to the human body.'
            },{
                alt: 'Economical Semi-Permanent Filter',
                image: Feature_06,
                title: 'Economical Semi-Permanent Filter',
                desc: 'The photocatalyst filter guarantees semi-permanent use: Boiling for just 20 minutes restores and maintains its original performance. ',
                descMobile: 'The photocatalyst filter guarantees semi-permanent use: Boiling for just 20 minutes restores and maintains its original performance. '
            },{
                alt: 'Outstanding Photocatalyst Coating',
                image: Feature_07,
                title: 'Outstanding Photocatalyst Coating',
                desc: 'The photocatalyst substance does not detach from its support structure and maintains excellent adhesive strength. ',
                descMobile: 'The photocatalyst substance does not detach from its support structure and maintains excellent adhesive strength. '
            }]
        }, {
            title: 'Product Family',
            caution: '* You can check the details when selecting a product.',
            readmore: 'View detail',
            contents: [{
                title: 'Deodorizing module (Customizing)',
                items: [{
                    alt: 'CMA-FBA-DK3A',
                    image: Product_11,
                    name: 'CMA-FBA-DK3A',
                    desc: '',
                    link: 'http://www.seoulviosys.com/en/product/new.asp'
                }, {
                    alt: 'CMA-FBM-CO4A',
                    image: Product_12,
                    name: 'CMA-FBM-CO4A',
                    desc: '',
                    link: 'http://www.seoulviosys.com/en/product/new.asp'
                }]
            }, {
                title: 'Pholotcatalyst filter (Customizing)',
                items: [{
                    alt: 'PCF Series',
                    image: Product_13,
                    name: 'PCF Series',
                    desc: '',
                    link: 'http://www.seoulviosys.com/en/product/new.asp'
                }]
            }]
        }, {
            title: 'Application Case',
            caution: '* You can check the details when selecting a product.',
            readmore: 'View detail',
            contents: [{
                alt: 'Refrigerators',
                image: Example_14,
                name: 'Refrigerators',
                link: '/en/applications/deodorization?product=refrigerator#refrigerator',
                width: '130'
            }, {
                alt: 'Air Purifiers',
                image: Example_01,
                name: 'Air Purifiers',
                link: '/en/applications/deodorization?product=airpurifier#airpurifier',
                width: '83'
            }, {
                alt: 'Dishwashers',
                image: Example_08,
                name: 'Dishwashers',
                link: '/en/applications/deodorization?product=dishwasher#dishwasher',
                width: '130'
            }, {
                alt: 'Steam Closets',
                image: Example_09,
                name: 'Steam Closets',
                link: '/en/applications/deodorization?product=steamCloset#steamCloset',
                width: '130'
            }]
        }]
    },

    insectAttraction: {
        category: 'Insect Attraction',
        header: 'The UV LED technology <span style="font-weight: normal">developed by </span>Seoul Viosys <span style="font-weight: normal">has an attraction rate that is </span>13 times greater than existing insect attraction technologies <span style="font-weight: normal">that use mercury lamps. The technology provides an optimized and </span>effective pest attraction solution <span style="font-weight: normal">according to customers\' needs.</span>',
        headerMobile: 'The UV LED technology <span style="font-weight: normal">developed by </span>Seoul Viosys <span style="font-weight: normal">has an attraction rate that is </span>13 times greater than existing insect attraction technologies <span style="font-weight: normal">that use mercury lamps. The technology provides an optimized and </span>effective pest attraction solution <span style="font-weight: normal">according to customers\' needs.</span>',
        section: [{
            title: 'Features',
            contents: [{
                alt: 'Up to 13 times greater performance in attracting mosquitoes',
                image: Feature_08,
                title: 'Up to 13 times greater performance in attracting mosquitoes',
                desc: 'Up to 13 times more effective in trapping Aedes aegypti, a transmitter of the Zika virus that causes microcephaly, compared to existing mercury lamp insect attracting solutions. (Based on studies from the Insect Research Center, Florida State University, U.S.)',
                descMobile: 'Up to 13 times more effective in trapping Aedes aegypti, a transmitter of the Zika virus that causes microcephaly, compared to existing mercury lamp insect attracting solutions. (Based on studies from the Insect Research Center, Florida State University, U.S.)'
            }, {
                alt: 'Zero Toxic Chemicals',
                image: Feature_02,
                title: 'Zero Toxic Chemicals',
                desc: 'Free of heavy metals, such as mercury, and chemicals, such as pesticides, it is safe to use, and non-toxic to the human body. ',
                descMobile: 'Free of heavy metals, such as mercury, and chemicals, such as pesticides, it is safe to use, and non-toxic to the human body. '
            }, {
                alt: 'Long Lifetime',
                image: Feature_03,
                title: 'Long Lifetime',
                desc: 'Long lifetime of 20,000 hrs according to L50 standards allows for reduced replacement costs (Z5 PKG Standard).',
                descMobile: 'Long lifetime of 20,000 hrs according to L50 standards allows for reduced replacement costs (Z5 PKG Standard).'
            }, {
                alt: 'Low Energy Consumption',
                image: Feature_04,
                title: 'Low Energy Consumption',
                desc: 'Minimal electricity costs with an extremely low energy consumption rate.',
                descMobile: 'Minimal electricity costs with an extremely low energy consumption rate.'
            }]
        }, {
            title: 'Product Family',
            caution: '* You can check the details when selecting a product.',
            readmore: 'View detail',
            contents: [{
                title: 'Insect Attraction Module',
                items: [{
                    alt: 'Customizing Module (T8-436mm)',
                    image: Product_06,
                    name: 'Customizing Module',
                    desc: '(T8-436mm)',
                    link: 'http://www.seoulviosys.com/en/product/new.asp'
                }]
            }]
        }, {
            title: 'Application Case ',
            caution: '* You can check the details when selecting a product.',
            readmore: 'View detail',
            contents: [{
                alt: 'Insect Trap',
                image: Example_10,
                name: 'Insect Trap',
                link: '/en/applications/insect-attraction?product=insectTrap#insectTrap',
                width: '130'
            }]
        }]
    },
    
    curing: {
        category: 'Curing',
        header: 'Seoul Viosys is the only UV LED manufacturer <span style="font-weight: normal">in the world that covers the entire UV spectrum (255–405 nm), providing</span> a UV LED curing solution <span style="font-weight: normal">optimized for customers\' needs.</span>',
        headerMobile: 'Seoul Viosys is the only UV LED manufacturer <span style="font-weight: normal">in the world that covers the entire UV spectrum (255–405 nm), providing</span> a UV LED curing solution <span style="font-weight: normal">optimized for customers\' needs.</span>',
        section: [{
            title: 'Features',
            contents: [{
                alt: 'Entire UV spectrum (255–405 nm)',
                image: Feature_09,
                title: 'Entire UV spectrum (255–405 nm)',
                desc: 'Includes a compatible chip, PKG and module suitable for optimal UV wavelengths for curing various materials and curing equipment.',
                descMobile: 'Includes a compatible chip, PKG and module suitable for optimal UV wavelengths for curing various materials and curing equipment.'
            }, {
                alt: 'Flexibility in Equipment Design',
                image: Feature_10,
                title: 'Flexibility in Equipment Design',
                desc: 'The chip, PKG, COB, and module supply can be supplied in any form to meet the requirements of the respective conditions for each process, from exposure kits to equipment design by manufacturers.',
                descMobile: 'The chip, PKG, COB, and module supply can be supplied in any form to meet the requirements of the respective conditions for each process, from exposure kits to equipment design by manufacturers.'
            }, {
                alt: 'Low Power Consumption and Process Convenience',
                image: Feature_04,
                title: 'Low Power Consumption and Process Convenience',
                desc: 'Power consumption is a maximum of 30% compared to conventional mercury, metal halide, or xenon lamps, effectively reducing process costs. In addition, instantaneous ON/OFF and immediate operation are possible compared to the time delay when switching ON and the time required for output stabilization with conventional light sources.',
                descMobile: 'Power consumption is a maximum of 30% compared to conventional mercury, metal halide, or xenon lamps, effectively reducing process costs. In addition, instantaneous ON/OFF and immediate operation are possible compared to the time delay when switching ON and the time required for output stabilization with conventional light sources.',
            }, {
                alt: 'Work Safety',
                image: Feature_11,
                title: 'Work Safety',
                desc: 'Compared to conventional UV Lamps, the relatively lower voltage reduces occupational risks, such as electric shocks.',
                descMobile: 'Compared to conventional UV Lamps, the relatively lower voltage reduces occupational risks, such as electric shocks.',
            }, {
                alt: 'Zero Toxic Chemicals',
                image: Feature_02,
                title: 'Zero Toxic Chemicals',
                desc: 'Unlike mercury lamps, it is eco-friendly and free of mercury and toxic substances.',
                descMobile: 'Unlike mercury lamps, it is eco-friendly and free of mercury and toxic substances.',
            }]
        }, {
            title: 'Product Family',
            caution: '* You can check the details when selecting a product.',
            readmore: 'View detail',
            contents: [{
                title: 'PKG',
                items: [{
                    alt: 'Z5 PKG (1chip)',
                    image: Product_07,
                    name: 'Z5 PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/en/product/new.asp'
                }, {
                    alt: 'nZ5 PKG',
                    image: Product_08,
                    name: 'nZ5 PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/en/product/new.asp'
                }, {
                    alt: 'CA PKG (1chip)',
                    image: Product_09,
                    name: 'CA PKG',
                    desc: '(1chip)',
                    link: 'http://www.seoulviosys.com/en/product/new.asp'
                }]
            }, {
                title: 'Module',
                items: [{
                    alt: 'NCOB (9chip)',
                    image: Product_10,
                    name: 'NCOB',
                    desc: '(9chip)',
                    link: 'http://www.seoulviosys.com/en/product/new.asp'
                }]
            }]
        }, {
            title: 'Application Case',
            caution: '* You can check the details when selecting a product.',
            readmore: 'View detail',
            contents: [{
                alt: 'Printers',
                image: Example_11,
                name: 'Printers',
                link: '/en/applications/curing?product=printer#printer',
                width: '130'
            }, {
                alt: 'Industrial Curing',
                image: Example_12,
                name: 'Industrial Curing',
                link: '/en/applications/curing?product=industrialCuring#industrialCuring',
                width: '130'
            }, {
                alt: 'Nail Curing',
                image: Example_05,
                name: 'Nail Curing',
                link: '/en/applications/curing?product=nailCuring#nailCuring',
                width: '82'
            }]
        }]
    }
}