import styled from 'styled-components'
import React from 'react'
import { useTranslation } from 'react-i18next'

const Indicator = ({ onCircleClick, current }) => {

    const { t } = useTranslation('common')

    return (
        <Wrapper>
            <IndicatorList>
                <IndicatorListItem onClick={e=>onCircleClick('BrandReview')} selected={current === 0}>
                    <p>{t('header.sideMenu.review')}</p>
                    <Line />
                    {/* <OutterCircle>
                        {current === 0 && <InnerCircle />}
                    </OutterCircle> */}
                    
                </IndicatorListItem>
                <IndicatorListItem onClick={e=>onCircleClick('Technology')} selected={current === 1}>
                    <p>{t('header.sideMenu.tech')}</p>
                    <Line />
                    {/* <OutterCircle>
                        {current === 1 && <InnerCircle />}
                    </OutterCircle> */}
                    
                </IndicatorListItem>
                <IndicatorListItem onClick={e=>onCircleClick('UVApplicationField')} selected={current === 2}>
                    <p>{t('header.sideMenu.uv')}</p>
                    <Line />
                    {/* <OutterCircle>
                        {current === 2 && <InnerCircle />}
                    </OutterCircle> */}
                    
                </IndicatorListItem>
                <IndicatorListItem onClick={e=>onCircleClick('Media')} selected={current === 3}>
                    <p>{t('header.sideMenu.media')}</p>
                    <Line />
                    {/* <OutterCircle>
                        {current === 3 && <InnerCircle />}
                    </OutterCircle> */}
                    
                </IndicatorListItem>
            </IndicatorList>
        </Wrapper>
    )

}

const Wrapper = styled.div`
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 200;
    transition: .3s;
    @media screen and (max-width: 767px) {
        display: none;
    }
`

const IndicatorList = styled.li`
    
    display: flex;
    flex-direction: column;
    align-items: flex-end;

`

const IndicatorListItem = styled.li`
    cursor: pointer;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    opacity: ${props=>props.selected ? '0.72' : '0.24'};
    transition: .3s;

    &:last-child {
        margin-bottom: 0;
    }

    p {
        margin-bottom: 0;
        font-size: 12px;
        font-weight: 500;
        color: #aaa;
    }

    &:hover {
        opacity: 0.72;
    }
`

const Line = styled.div`
    width: 12px;
    height: 3px;
    background-color: #aaa;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    margin-left: 10px;
`


const OutterCircle = styled.div`
    position: relative;
    cursor: pointer;
    width: 12px;
    height: 12px;
    border: 1px solid #aaa;
    margin: 0 10px 0 12px;
    border-radius: 6px;
    padding: 1px;
`

const InnerCircle = styled.div`
    width: 100%;
    height: 100%;
    background-color: #aaa;
    border-radius: 5px;
`



export default Indicator