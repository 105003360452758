import React from 'react'
import { Helmet } from 'react-helmet'
import { withRouter } from 'react-router-dom'

const Head = ({ 
    title = '', 
    description = '', 
    keyword = '', 
    ogImage = 'https://violeds.com/ogimage.jpg', 
    url = 'https://violeds.com' ,
    lang = '',
    ...props
}) => {
    return (
        <Helmet>
            
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keywords" content={keyword} />
            <link rel="canonical" href={`https://violeds.com${lang}`}></link>
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content={"violeds"} />
            <meta property="og:url" content={`https://violeds.com${props.location.pathname}`} />
            <meta property="og:title" content={title} />
            <meta
                property="og:description"
                content={description}
            />
            <meta name="twitter:site" content={`https://violeds.com${props.location.pathname}`} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:image" content={ogImage} />
            <meta property="og:image" content={ogImage} />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
        </Helmet>
    )
}

export default withRouter(Head)