import styled from 'styled-components'
import React, { useState, useEffect, useRef } from "react";
import GuideIco from '../images/vr/guide_ico.png'
import GuideIcoMB from '../images/vr/guide_ico_mo.png'
import CloseIco from '../images/vr/guide_close.png'

const VrGuide = ({ onCloseGuide, data }) => {

    const vrGuideRef = useRef();
    const [isMobile, setIsMobile] = useState()

    useEffect(() => {
        const resizeHandle = () => {
            if (typeof window !== 'undefined') {
                const winWidth = window.innerWidth;
                if (winWidth <= 900) setIsMobile(true)
                else setIsMobile(false)
            }
        }
        resizeHandle();

        window.addEventListener('resize', resizeHandle)
        return () => window.removeEventListener('resize', resizeHandle)

    }, [])

    return (
        <Wrapper onClick={(e) => {
            if (e.target !== e.currentTarget) return;
            else onCloseGuide()}}>
            <VrGuideWrap ref={vrGuideRef}>
                <CloseBtn onClick={onCloseGuide} src={CloseIco} />
                <VrGuideContents>
                    <VrGuideTitle>{data.title}</VrGuideTitle>
                    <VrIco src={!isMobile ? GuideIco : GuideIcoMB} />
                    <VrGuideDesc>{!isMobile ? data.desc.pc : data.desc.mo}</VrGuideDesc>
                </VrGuideContents>
            </VrGuideWrap>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.3);
    z-index: 10000;

    @media screen and (max-width: 900px) and (orientation: portrait) {
        display: none;
    }
`

const VrGuideWrap = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 80px;
    background: rgba(34, 34, 34, .9);
    border: 2px solid #fff;
    border-radius: 20px;
    z-index: 10001;

    @media screen and (max-width: 900px) {
        padding: 40px 39px 30px;
        top: 53%;
    }
`

const CloseBtn = styled.img`
    position: absolute;
    right: 12px;
    top: 12px;
    cursor: pointer;

    @media screen and (max-width: 900px) {
        right: 7px;
        top: 7px;
    }
`

const VrGuideContents = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const VrIco = styled.img`
    margin: 40px 0;
    width: 100%;
    max-width: 134px;
    
    @media screen and (max-width: 900px) {
        max-width: 90px;
        margin: 13px 0;
    }
`

const VrGuideTitle = styled.div`
    color: #fff;
    font-size: 28px;
    font-weight: bold;
    white-space: pre-line;
    text-align:center;

    @media screen and (max-width: 900px) {
        font-size: 18px;
    }
`

const VrGuideDesc = styled.div`
    color: #fff;
    font-size: 20px;
    white-space: pre-line;
    text-align: center;
    
    @media screen and (max-width: 900px) {
        font-size: 12px;
    }
`

export default VrGuide