import styled from 'styled-components'
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import mapHome from '../images/vr/vr_map_home.png'
import mapCafe from '../images/vr/vr_map_cafe.png'
import mapIcoMb from '../images/vr/mapIco_mb.png'
import mapImgMb from '../images/vr/elevation_map.png'
import mapImgMbCafe from '../images/vr/elevation_map_cafe.png'
import closeBtn from '../images/vr/close_btn.png'
import MapMark from '../components_vr/MapMark'
import MapMarkBath from '../images/vr/vr_map_bath.png'
import MapMarkBed from '../images/vr/vr_map_bed.png'
import MapMarkKitchen from '../images/vr/vr_map_kitchen.png'
import MapMarkLaundry from '../images/vr/vr_map_laundry.png'
import MapMarkLiving from '../images/vr/vr_map_living.png'
import MapMarkRsHall from '../images/vr/vr_map_rs_hall.png'
import MapMarkRsKitchen from '../images/vr/vr_map_rs_kitchen.png'
import { useTranslation } from 'react-i18next'


const MapRooms = ({ data, space, room, ...props }) => {

    const { t } = useTranslation('application_vr');
    const [isMobile, setIsMobile] = useState(false)
    const [showMobileMap, setShowMobileMap] = useState(false)
    const [active, setActive] = useState();
    
    useEffect(() => {
        const resizeHandle = async () => {
            if (typeof window !== 'undefined') {
                const winWidth = window.innerWidth;
                if (winWidth <= 900) {
                    await setIsMobile(true)
                }
                else {
                    await setIsMobile(false)
                }
            }
        }

        resizeHandle();
        
        window.addEventListener('resize', resizeHandle)
        return () => window.removeEventListener('resize', resizeHandle)
    }, [])

    const getMapImg = () => {
        if (props.location.pathname === `${props.currentLocale}/showroom/laundry-room`) setActive(MapMarkLaundry)
        if (props.location.pathname === `${props.currentLocale}/showroom/kitchen`) setActive(MapMarkKitchen)
        if (props.location.pathname === `${props.currentLocale}/showroom/bedroom`) setActive(MapMarkBed)
        if (props.location.pathname === `${props.currentLocale}/showroom/living-room`) setActive(MapMarkLiving)
        if (props.location.pathname === `${props.currentLocale}/showroom/bathroom`) setActive(MapMarkBath)
        if (props.location.pathname === `${props.currentLocale}/showroom/rs-kitchen`) setActive(MapMarkRsKitchen)
        if (props.location.pathname === `${props.currentLocale}/showroom/rs-hall`) setActive(MapMarkRsHall)
    }

    
    return (
        <Wrapper>
            {isMobile ?
            <MapMobile onClick={() => setShowMobileMap(!showMobileMap)}>
                <MapImgMb src={mapIcoMb} />
            </MapMobile>
            :
            <MapPc>
                {space === 'home' && (
                    <MapImg 
                    src={mapHome} />
                    // src={active ? active : getMapImg()} />
                )}
                {space === 'cafeteria' && (
                    <MapImg src={mapCafe} />    
                )}
                
                    <MapAreaImg img={active ? active : getMapImg()} />

                {space === 'home' && (
                    <>
                        <MapMark 
                        name={`${t('showroom.laundry')}`} 
                        url={`${props.currentLocale}/showroom/laundry-room`} 
                        top={34}
                        left={7}
                        width={28}
                        height={14}
                        MarkTop={20}
                        MarkLeft={50}
                        show={active == MapMarkLaundry}
                        onHoverMark={() => setActive(MapMarkLaundry)}
                        onNotHoverMark={getMapImg} />
                        <MapMark 
                        name={`${t('showroom.kitchen')}`} 
                        url={`${props.currentLocale}/showroom/kitchen`} 
                        top={46}
                        left={20}
                        width={20}
                        height={31}
                        MarkTop={63}
                        MarkLeft={65}
                        show={active == MapMarkKitchen}
                        onHoverMark={() => setActive(MapMarkKitchen)}
                        onNotHoverMark={getMapImg} />
                        <MapMark 
                        name={`${t('showroom.bedroom')}`} 
                        url={`${props.currentLocale}/showroom/bedroom`} 
                        top={30}
                        left={56}
                        width={18} 
                        height={30}
                        MarkTop={66}
                        MarkLeft={48}
                        show={active == MapMarkBed}
                        onHoverMark={() => setActive(MapMarkBed)}
                        onNotHoverMark={getMapImg} />
                        <MapMark 
                        name={`${t('showroom.living')}`} 
                        url={`${props.currentLocale}/showroom/living-room`} 
                        top={37}
                        left={36}
                        width={21}
                        height={32}
                        MarkTop={60}
                        MarkLeft={40}
                        show={active == MapMarkLiving}
                        onHoverMark={() => setActive(MapMarkLiving)}
                        onNotHoverMark={getMapImg} />
                        <MapMark 
                        name={`${t('showroom.bath')}`} 
                        url={`${props.currentLocale}/showroom/bathroom`} 
                        top={26}
                        left={41}
                        width={20}
                        height={11}
                        MarkTop={41}
                        MarkLeft={64}
                        show={active == MapMarkBath}
                        onHoverMark={() => setActive(MapMarkBath)}
                        onNotHoverMark={getMapImg} />
                    </>
                )}
                {space === 'cafeteria' && (
                    <>
                        <MapMark 
                        name={`${t('showroom.hall')}`} 
                        url={`${props.currentLocale}/showroom/rs-hall`} 
                        top={23}
                        left={45}
                        width={39}
                        height={46}
                        MarkTop={57}
                        MarkLeft={46}
                        show={active == MapMarkRsHall}
                        onHoverMark={() => setActive(MapMarkRsHall)}
                        onNotHoverMark={getMapImg} />
                        <MapMark 
                        name={`${t('showroom.kitchen')}`} 
                        url={`${props.currentLocale}/showroom/rs-kitchen`} 
                        top={34}
                        left={14}
                        width={33}
                        height={46}
                        MarkTop={34}
                        MarkLeft={73}
                        show={active == MapMarkRsKitchen}
                        onHoverMark={() => setActive(MapMarkRsKitchen)}
                        onNotHoverMark={getMapImg} />
                    </>
                )}
                
            </MapPc>
            }
            {showMobileMap &&
            <MobileMapWrap>
                <MobileMapNav>
                    <Space />
                    {space === 'home' && (
                        <MobileMapNavTxt>`${t('showroom.home')}`</MobileMapNavTxt>    
                    )}
                    {space === 'cafeteria' && (
                        <MobileMapNavTxt>`${t('showroom.cafeteria')}`</MobileMapNavTxt>    
                    )}
                    
                    <CloseBtn src={closeBtn} onClick={e=> setShowMobileMap(!showMobileMap)} />
                </MobileMapNav>
                <MobileMapFlex>
                    <MbMapRooms>
                        {space === 'home' && (
                            <MbMapWrapper bg={mapImgMb}>
                                <MapMark 
                                name={`${t('showroom.laundry')}`} 
                                url={`${props.currentLocale}/showroom/laundry-room`} 
                                active={props.location.pathname === `${props.currentLocale}/showroom/laundry-room`}
                                top={36}
                                left={17} />
                                <MapMark 
                                name={`${t('showroom.kitchen')}`} 
                                url={`${props.currentLocale}/showroom/kitchen`} 
                                active={props.location.pathname === `${props.currentLocale}/showroom/kitchen`}
                                top={48}
                                left={26} />
                                <MapMark 
                                name={`${t('showroom.living')}`} 
                                url={`${props.currentLocale}/showroom/living-room`} 
                                active={props.location.pathname === `${props.currentLocale}/showroom/living-room`}
                                top={43}
                                left={38} />
                                <MapMark 
                                name={`${t('showroom.bath')}`} 
                                url={`${props.currentLocale}/showroom/bathroom`} 
                                active={props.location.pathname === `${props.currentLocale}/showroom/bathroom`}
                                top={23}
                                left={50} />
                                <MapMark 
                                name={`${t('showroom.bedroom')}`} 
                                url={`${props.currentLocale}/showroom/bedroom`} 
                                active={props.location.pathname === `${props.currentLocale}/showroom/bedroom`}
                                top={34}
                                left={58} />
                            </MbMapWrapper>
                        )}
                        {space === 'cafeteria' && (
                            <MbMapWrapper bg={mapImgMbCafe}>
                                <MapMark 
                                name={`${t('showroom.kitchen')}`} 
                                url={`${props.currentLocale}/showroom/rs-kitchen`} 
                                active={props.location.pathname === `${props.currentLocale}/showroom/rs-kitchen`}
                                top={53}
                                left={36} />
                                <MapMark 
                                name={`${t('showroom.hall')}`} 
                                url={`${props.currentLocale}/showroom/rs-hall`} 
                                active={props.location.pathname === `${props.currentLocale}/showroom/rs-hall`}
                                top={38}
                                left={59} />
                                
                            </MbMapWrapper>
                        )}
                        
                        
                    </MbMapRooms>
                    <MbMapRooms>
                        <MbMapRoomsList>
                            {data[space]?.map((list, idx) => {
                                return (
                                    <RoomsBox key={String(idx)}>
                                        <RoomsBg bg={list.mbTitleBg}>
                                            <RoomsInner href={list.url} isLine={props.location.pathname === list.url ? true : false}>
                                                <Txt>{list.name}</Txt>
                                            </RoomsInner>
                                        </RoomsBg>
                                        
                                    </RoomsBox>
                                )
                            })}
                        </MbMapRoomsList>
                    </MbMapRooms>
                </MobileMapFlex>
            </MobileMapWrap>
            }
        </Wrapper>
    )
}

const Wrapper = styled.div`
`

const MapPc = styled.div`
    position: fixed;
    bottom: -30px;
    left: 30px;
    display: block;

    @media screen and (max-width: 900px) and (orientation: landscape) {
        display: none;
    }
`

const MapImg = styled.img`
    display: block;
    width: 460px;
    height: 404px;
    object-fit: cover;
`

const MapAreaImg = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: url(${props => props.img}) no-repeat center / cover;
`

const MapMobile = styled.div`
    position: fixed;
    bottom: 16px;
    left: 16px;
    display: none;
    align-items: center;
    justify-content: center;
    width: 53px;
    height: 53px;
    background-color: rgba(0,0,0,.8);
    border-radius: 100%;
    cursor: pointer;
    z-index: 81;

    @media screen and (max-width: 900px) and (orientation: landscape) {
        display: flex;
    }
`

const MapImgMb = styled.img`
    width: 33px;
`

const MobileMapWrap = styled.div`
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 82;
    background-color: #555;
`

const MobileMapNav = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    background: rgba(32, 32, 32, .32);
    text-align: center;
    padding: 0 16px;
    z-index: 83;
`

const Space = styled.div`
`

const MobileMapNavTxt = styled.div`
    font-size: 16px;
    color: #fff;
    font-weight: bold;
`

const CloseBtn = styled.img`
    width: 18px;
`

const MobileMapFlex = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`

const MbMapRooms = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`
const MbMapWrapper = styled.div`
    position: relative;
    width: 86%;
    height: 0;
    padding-top: calc(290 / 330 * 86%);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url(${props=>props.bg});
`

const MbMapImg = styled.img`
    width: 82%;
    max-width: 350px;
`
const MbMapRoomsList = styled.ul`
    position: relative;
    width: 88%;
    margin: 0 auto;
`

const RoomsBox = styled.li`
    position: relative;
    width: calc(50% - 16px);
    height: 13%;
    margin: 8px;
    float: left;
`

const RoomsBg = styled.div`
    position: relative;
    width: 100%;
    height: 0;
    padding-top: calc(52 / 164 * 100%);
    background: url(${props => props.bg}) no-repeat center / contain;
`

const RoomsInner = styled.a`
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    overflow: hidden;
    ${props=>props.isLine && 'border: 3px solid #fff;'}
`

const Txt = styled.p`
    color: #fff;
    font-size: 16px;
    font-weight: 900;
    margin-bottom: 0;
`



export default withRouter(MapRooms)