const Media = {
    header: {
        all: {
            title: 'violeds | MEDIA - All',
            desc: 'Violeds has become a vital part of our daily lives because of its proximity. Meet a more approachable violeds through our different channels.',
            keyword: 'violeds, Violeds, violeds, Seoulvyosis, media'
        },
        influencer: {
            title: 'violeds | MEDIA - Influencer',
            desc: 'Violeds has become a vital part of our daily lives because of its proximity. Meet a more approachable violeds through our different channels.',
            keyword: 'violeds, Violeds, violeds, Seoulvyosis, media, influencer'
        },
        social: {
            title: 'violeds | MEDIA - Social Content',
            desc: 'Violeds has become a vital part of our daily lives because of its proximity. Meet a more approachable violeds through our different channels.',
            keyword: 'violeds, Violeds, violeds, Seoulvyosis, media, social contents, social'
        },
        news: {
            title: 'violeds | MEDIA - Press Release',
            desc: 'Violeds has become a vital part of our daily lives because of its proximity. Meet a more approachable violeds through our different channels.',
            keyword: 'violeds, Violeds, violeds, Seoulvyosis, media, press release, news'
        }
    },
    category: ['All', 'Social Content', 'Influencer', 'Press Release'],
    mediaType: ['Article', 'Card News', 'Video'],
    list: {
        next: 'Next',
        prev: 'Prev',
        golist: 'List',
        noNext: 'There is no previous post.',
        noPrev: 'There is no next post.',
        related: 'Related content'
    },
    api: {
        list: '/media/en/list',
        detail: '/media/en',
        preNext: '/media/en/pre-next',
        related: '/media/en/relative'
    }
}

export default Media