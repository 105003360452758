import app01 from '../../images/applications/application01@3x.png'
import app02 from '../../images/applications/application02@3x.png'
import app03 from '../../images/applications/application03@3x.png'
import app04 from '../../images/applications/application04@3x.png'
import app05 from '../../images/applications/application05@3x.png'
import app06 from '../../images/applications/application06@3x.png'
import app07 from '../../images/applications/application07@3x.png'
import app08 from '../../images/applications/application08@3x.png'
import app09 from '../../images/applications/application09@3x.png'
import app10 from '../../images/applications/application10@3x.png'
import app11 from '../../images/applications/application11@3x.png'
import app12 from '../../images/applications/application12@3x.png'
import app13 from '../../images/applications/application13@3x.png'
import app14 from '../../images/applications/application14@3x.png'
import app15 from '../../images/applications/application15@3x.png'
import app16 from '../../images/applications/application16@3x.png'
import app17 from '../../images/applications/application17@3x.png'
import app18 from '../../images/applications/application18@3x.png'
import app19 from '../../images/applications/application19@3x.png'
import app20 from '../../images/applications/application20@3x.png'
import app21 from '../../images/applications/application21@3x.png'
import app22 from '../../images/applications/application22@3x.png'

import item01 from '../../images/main/applications/round_on01@2x.png'
import item02 from '../../images/main/applications/round_on02@2x.png'
import item03 from '../../images/main/applications/round_on03@2x.png'
import item04 from '../../images/main/applications/round_on04@2x.png'
import item05 from '../../images/main/applications/round_on05@2x.png'
import item06 from '../../images/main/applications/round_on06@2x.png'
import item07 from '../../images/main/applications/round_on07@2x.png'
import item08 from '../../images/main/applications/round_on08@2x.png'
import item09 from '../../images/main/applications/round_on09@2x.png'
import item10 from '../../images/main/applications/round_on10@2x.png'
import item11 from '../../images/main/applications/round_on11@2x.png'
import item12 from '../../images/main/applications/round_on12@2x.png'
import item13 from '../../images/main/applications/round_on13@2x.png'
import item14 from '../../images/main/applications/round_on14@2x.png'
import item15 from '../../images/main/applications/round_on15@2x.png'
import item16 from '../../images/main/applications/round_on16@2x.png'
import item17 from '../../images/main/applications/round_on17@2x.png'
import item18 from '../../images/main/applications/round_on18@2x.png'
import item19 from '../../images/main/applications/round_on19@2x.png'
import item20 from '../../images/main/applications/round_on20@2x.png'
import item21 from '../../images/main/applications/round_on21@2x.png'
import item22 from '../../images/main/applications/round_on22@2x.png'

import item01_off from '../../images/main/applications/round01_off.png'
import item02_off from '../../images/main/applications/round02_off.png'
import item03_off from '../../images/main/applications/round03_off.png'
import item04_off from '../../images/main/applications/round04_off.png'
import item05_off from '../../images/main/applications/round05_off.png'
import item06_off from '../../images/main/applications/round06_off.png'
import item07_off from '../../images/main/applications/round07_off.png'
import item08_off from '../../images/main/applications/round08_off.png'
import item09_off from '../../images/main/applications/round09_off.png'
import item10_off from '../../images/main/applications/round10_off.png'
import item11_off from '../../images/main/applications/round11_off.png'
import item12_off from '../../images/main/applications/round12_off.png'
import item13_off from '../../images/main/applications/round13_off.png'
import item14_off from '../../images/main/applications/round14_off.png'
import item15_off from '../../images/main/applications/round15_off.png'
import item16_off from '../../images/main/applications/round16_off.png'
import item17_off from '../../images/main/applications/round17_off.png'
import item18_off from '../../images/main/applications/round18_off.png'
import item19_off from '../../images/main/applications/round19_off.png'
import item20_off from '../../images/main/applications/round20_off.png'
import item21_off from '../../images/main/applications/round21_off.png'
import item22_off from '../../images/main/applications/round22_off.png'

const ApplicationItems = {
    header: {
        all: {
            title: 'violeds | APPLICATIONS - 全体',
            desc: 'violeds技術の開発は日々続いています。 急速に拡大していくVioledsの適用例の最新情報を提供致します。',
            keyword: 'violeds、Violeds、バイオレッズ、ソウルバイオシス、applications、適用事例、成功事例'
        },
        sterilization: {
            title: 'violeds | APPLICATIONS - 除菌',
            desc: 'violeds技術の開発は日々続いています。 急速に拡大していくVioledsの適用例の最新情報を提供致します。',
            keyword: 'violeds、Violeds、バイオレッズ、ソウルバイオシス、applications、適用事例、成功事例、除菌'
        },
        deodorization: {
            title: 'violeds | APPLICATIONS - 脱臭',
            desc: 'violeds技術の開発は日々続いています。 急速に拡大していくVioledsの適用例の最新情報を提供致します。',
            keyword: 'violeds、Violeds、バイオレッズ、ソウルバイオシス、applications、適用事例、成功事例、脱臭'
        },
        insectAttraction: {
            title: 'violeds | APPLICATIONS - 捕虫',
            desc: 'violeds技術の開発は日々続いています。 急速に拡大していくVioledsの適用例の最新情報を提供致します。',
            keyword: 'violeds、Violeds、バイオレッズ、ソウルバイオシス、applications、適用事例、成功事例、捕虫'
        },
        curing: {
            title: 'violeds | APPLICATIONS - 硬化',
            desc: 'violeds技術の開発は日々続いています。 急速に拡大していくVioledsの適用例の最新情報を提供致します。',
            keyword: 'violeds、Violeds、バイオレッズ、ソウルバイオシス、applications、適用事例、成功事例、硬化'
        },
    },
    type: ['全体', '除菌', '脱臭', '捕虫', '硬化'],
    techBtn: {
        pc: ['除菌技術情報', '脱臭技術情報', '捕虫技術情報', '硬化技術情報'],
        mo: ['除菌', '脱臭', '捕虫', '硬化']
    },
    data: [{
        keyword: 'refrigerator',
        type: ['살균', '탈취'],
        alt: '冷蔵庫',
        img: app16,
        mainImg: {
            on: item16,
            off: item16_off
        },
        title: '冷蔵庫',
        desc: '冷蔵庫内部には、食材表面の汚染物質によって食中毒を起こす細菌である大腸菌、黄色ブドウ球菌が発生する恐れがあります。 violedsは、冷蔵庫内部に浮遊したり壁面についた菌を99.9%除菌·脱臭して、食材の長期間保管を実現します。',
        goApplications: '/jp/applications?product=refrigerator#refrigerator'
    }, {
        keyword: 'airConditioner',
        type: ['살균'],
        alt: 'エアコン',
        img: app22,
        mainImg: {
            on: item22,
            off: item22_off
        },
        title: 'エアコン',
        desc: "エアコン稼動中に発生する湿気は、熱交換器とファンに大腸菌、黄色ブドウ球菌、表皮ブドウ球菌などの有害細菌を発生させる恐れがあります。 フィルターとファンにvioledsのUVCを照射することで、有害細菌を除菌します。",
        goApplications: '/jp/applications?product=airConditioner#airConditioner'
    }, {
        keyword: 'washingMachine',
        type: ['살균'],
        alt: '洗濯機',
        img: app02,
        mainImg: {
            on: item02,
            off: item13_off
        },
        title: '洗濯機',
        desc: '洗濯後、洗濯槽の外表に残っている湿気は、悪臭や各種の細菌、カビを繁殖させる原因になります。violedsの除菌技術により洗濯槽外表の細菌を死滅させ、洗濯槽を常に清潔な状態に保ちます。',
        goApplications: '/jp/applications?product=washingMachine#washingMachine'
    }, {
        keyword: 'airpurifier',
        type: ['살균', '탈취'],
        alt: '空気清浄機',
        img: app01,
        mainImg: {
            on: item01,
            off: item01_off
        },
        title: '空気清浄機',
        desc: '飛沫に付着して漂う5㎛の大きさのウイルスは、空気清浄機のフィルターによって濾過され、フィルターが感染源に汚染される恐れがあります。 violedsは、空気清浄機フィルターに適用され、強力な除菌·脱臭効果を現れます。',
        goApplications: '/jp/applications?product=airpurifier#airpurifier',
    }, {
        keyword: 'waterPurifier',
        type: ['살균'],
        alt: 'ウォーターサーバー',
        img: app06,
        mainImg: {
            on: item06,
            off: item02_off
        },
        title: 'ウォーターサーバー',
        desc: '浄水器内部の溜まった水に発生しやすい細菌やカビを除菌します。 浄水器内部のコーク、直水管、アイスルーム、浄水器などをUV光で除菌し、浄水器内部の衛生状態をきれいに保ちます。',
        goApplications: '/jp/applications?product=waterPurifier#waterPurifier'
    }, {
        keyword: 'dishwasher',
        type: ['살균', '탈취'],
        alt: '食器洗浄機',
        img: app18,
        mainImg: {
            on: item18,
            off: item18_off
        },
        title: '食器洗浄機',
        desc: "排水溝に残った飲食物が原因で細菌が繁殖しやすい環境です。 violedsの除菌機能で細菌の増殖と悪臭を抑えられます。",
        goApplications: '/jp/applications?product=dishwasher#dishwasher'
    }, {
        keyword: 'vacuumCleaner',
        type: ['살균'],
        alt: '掃除機',
        img: app19,
        mainImg: {
            on: item19,
            off: item19_off
        },
        title: '掃除機',
        desc: '掃除機のゴミが保管される部分は、細菌が繁殖しやすい環境です。 violedsは、ほこり表面の細菌を除菌して掃除機の内部を衛生的に管理できます。',
        goApplications: '/jp/applications?product=vacuumCleaner#vacuumCleaner'
    }, {
        keyword: 'steamCloset',
        type: ['탈취'],
        alt: '衣類管理機',
        img: app20,
        mainImg: {
            on: item20,
            off: item20_off
        },
        title: '衣類管理機',
        desc: '衣類に深く染み込んだ匂いは衣類管理機の1次スチームで除去するのが難しい場合があります。 violedsに適用されたUVA+光触媒フィルターにより、残った匂いまで、もう一度強力に消臭します。',
        goApplications: '/jp/applications?product=steamCloset#steamCloset'
    }, {
        keyword: 'humidifier',
        type: ['살균'],
        alt: '加湿器',
        img: app21,
        mainImg: {
            on: item21,
            off: item21_off
        },
        title: '加湿器',
        desc: '加湿器の内部は、常に水が溜まっているので細菌が増殖しやすい環境です。 violedsは、水筒の内部にUVCを照射して生物膜(バイオフィルム)の生成を抑制し、内部に増殖する細菌を除菌することができ、加湿器管理を容易にします。',
        goApplications: '/jp/applications?product=humidifier#humidifier'
    }, {
        keyword: 'homeAppliance',
        type: [],
        alt: '家電製品',
        img: app17,
        mainImg: {
            on: item17,
            off: item17_off
        },
        title: '家電製品',
        desc: '生活の中の様々な家電製品にvioledsの技術力で作られた除菌、脱臭、捕虫機能を提供し、感染の心配がない日常生活を楽しんでいただけます。',
        goApplications: '/jp/applications?product=homeAppliance#homeAppliance'
    }, {
        keyword: 'portableAirpurifier',
        type: ['살균'],
        alt: '移動式空気清浄機',
        img: app03,
        mainImg: {
            on: item03,
            off: item03_off
        },
        title: '移動式空気清浄機',
        desc: '飛沫に付着して漂う5㎛の大きさのウイルスは、空気清浄機のフィルターによって濾過され、フィルターが感染源に汚染される恐れがあります。 violedsは、空気清浄機フィルターに適用され、強力な除菌効果を提供します。また、携帯も可能です。',
        goApplications: '/jp/applications?product=portableAirpurifier#portableAirpurifier'
    }, {
        keyword: 'disinfectingSurfaces',
        type: ['살균'],
        alt: '表面除菌機',
        img: app05,
        mainImg: {
            on: item05,
            off: item07_off
        },
        title: '表面除菌機',
        desc: 'マスクを含めた様々な生活用品の表面に付着している細菌とウイルスは人体に致命的な影響を及ぼす恐れがあります。 violedsは、99.9%の除菌力を発揮し、ウイルスや各種有害細菌の人体感染を予防します。',
        goApplications: '/jp/applications?product=disinfectingSurfaces#disinfectingSurfaces'
    }, {
        keyword: 'bidet',
        type: ['살균'],
        alt: 'ウォシュレット',
        img: app08,
        mainImg: {
            on: item08,
            off: item05_off
        },
        title: 'ウォシュレット',
        desc: '便座内部に設置される温水洗浄機器は、各種の細菌が繁殖しやすく、汚染に弱い環境です。 直接拭くのが困難なノズル、便座などにvioledsのUV LEDを適用して各種細菌を除菌できるので、常に清潔な環境を提供できます。',
        goApplications: '/jp/applications?product=bidet#bidet'
    }, {
        keyword: 'insectTrap',
        type: ['포충'],
        alt: '捕虫器',
        img: app09,
        mainImg: {
            on: item09,
            off: item06_off
        },
        title: '捕虫器',
        desc: "致命的なウイルス感染の媒体となる蚊のような害虫は、退治が必要です。 Violedsは虫を誘引できる最適化されたUV-A波長を活用し、蚊やハエのような害虫を誘引·捕集できます。",
        goApplications: '/jp/applications?product=insectTrap#insectTrap'
    }, {
        keyword: 'printer',
        type: ['경화'],
        alt: 'プリンター',
        img: app11,
        mainImg: {
            on: item11,
            off: item09_off
        },
        title: 'プリンター',
        desc: '特殊用紙を使用するプリントの場合、UVインクをUVの光に露出させ、瞬間硬化させる方法を使用します。 浸透性の低い特殊な用紙を使うことでインクの乾燥が早くできるので、印刷品質が高く鮮明な色合いを実現することができます。',
        goApplications: '/jp/applications?product=printer#printer'
    }, {
        keyword: 'industrialCuring',
        type: ['경화'],
        alt: '産業用硬化機',
        img: app12,
        mainImg: {
            on: item12,
            off: item11_off
        },
        title: '産業用硬化機',
        desc: '産業用製品生産の際に、素早く乾燥し、製品の硬度を高めることができます。 violeds技術は出力品質、速度、安定性に優れ、様々な素材に活用でき、効率的な作業環境を提供します。',
        goApplications: '/jp/applications?product=industrialCuring#industrialCuring'
    }, {
        keyword: 'nailCuring',
        type: ['경화'],
        alt: 'ネイル硬化機',
        img: app04,
        mainImg: {
            on: item04,
            off: item10_off
        },
        title: 'ネイル硬化機',
        desc: 'マニキュアを素早く乾燥し、硬度を上げることができます。 簡単で素早い硬化作用によりネイルアートに効率的で、長期間爪の管理が容易で爪の健康を守れます。',
        goApplications: '/jp/applications?product=nailCuring#nailCuring'
    }, {
        keyword: 'counterfeitDetection',
        type: [],
        alt: '偽札鑑別機',
        img: app10,
        mainImg: {
            on: item10,
            off: item08_off
        },
        title: '偽札鑑別機',
        desc: '偽造紙幣かどうかを判別する様々な機関などで使用します。 貨幣に特殊に印刷された標識をUV光で調べることで、偽造有無を容易に判別することができます。',
        goApplications: '/jp/applications?product=insectTrap#insectTrap'
    }, {
        keyword: 'medicalDevice',
        type: [],
        alt: '医療機器',
        img: app14,
        mainImg: {
            on: item14,
            off: item14_off
        },
        title: '医療機器',
        desc: '様々な分野の医療機器にもvioledsが活用されています。 白斑症の皮膚治療器、インプラント機器、タンニング機器などに使用され、安全で確実な医療環境を提供します。',
        goApplications: '/jp/applications?product=medicalDevice#medicalDevice'
    }, {
        keyword: 'blackLightBulb',
        type: [],
        alt: 'ブラックライト',
        img: app15,
        mainImg: {
            on: item15,
            off: item15_off
        },
        title: 'ブラックライト',
        desc: 'violeds技術力は，LEDが使用されている様々な場所で活用されています。 舞台照明や研究室検査機器(非破壊検査、異物およびパーティクル検査)に使用され、様々な分野で常に最適な環境を提供します。',
        goApplications: '/jp/applications?product=blackLightBulb#blackLightBulb'
    }]
}

export default ApplicationItems