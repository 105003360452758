import React, { useRef, useState, Fragment, useEffect } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import company_01 from '../images/company/company_01.png'
import section2_img1 from '../images/company/company_02.png'
import section2_img2 from '../images/company/company_03.png'
import next_btn from '../images/company/company_next.png'
import next_btn_on from '../images/company/company_next_on.png'
import prev_btn from '../images/company/company_prev.png'
import prev_btn_on from '../images/company/company_prev_on.png'
import Slider from "react-slick";
import Tabs from '../components/Tabs'
import Banner from '../components/Banner'
import { Fade } from 'react-reveal';
import Head from '../components/Head'
import { useTranslation } from 'react-i18next'
import CompanyHistory from '../components/CompanyHistory'

const Company = ({ ...props }) => {
    const { t } = useTranslation('company')
    const alt = t('alt', { returnObjects: true })

    const slider = useRef(null);
    const slidermb = useRef(null);
    const [index, setIndex] = useState(0);
    const [indexMB, setIndexMB] = useState(0);
    const [isMobile, setIsMobile] = useState()

    useEffect(() => {

        const resizeHandle = () => {
            if (typeof window !== 'undefined') {
                const winWidth = window.innerWidth;
                if (winWidth <= 767) setIsMobile(true)
                else setIsMobile(false)
            }
        }

        resizeHandle();
        
        window.addEventListener('resize', resizeHandle)
        return () => window.removeEventListener('resize', resizeHandle)
    }, [])

    const settings = {
        dots : false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows : false,
        draggable : false,
        ref : slider,
        afterChange: async function(currentSlide) {
            setIndex(currentSlide);
        }
    }

    const settingsMB = {
        dots : false,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows : false,
        draggable : false,
        ref : slidermb,
        variableWidth: true,
        afterChange: async function(currentSlide) {
            setIndexMB(currentSlide);
        }
    }

    const onClickPrev = (e) => {
        slider.current.slickPrev();
        slidermb.current.slickPrev();
    }
    const onClickNext = (e) => {
        slider.current.slickNext();
        slidermb.current.slickNext();
    }
    
    const tabItems = t('tabs', { returnObjects: true });

    const section1List = [
        {
            left : 'section1.title1.left',
            right : 'section1.title1.right',
            pwidth : 'section1.title1.pwidth',
            mwidth : 'section1.title1.mwidth'
        },
        {
            left : 'section1.title2.left',
            right : 'section1.title2.right',
            pwidth : 'section1.title2.pwidth',
            mwidth : 'section1.title2.mwidth'
        },
        {
            left : 'section1.title3.left',
            right : 'section1.title3.right',
            pwidth : 'section1.title3.pwidth',
            mwidth : 'section1.title3.mwidth'
        }
    ]

    return (
        <Wrapper>
            <Head title={t('meta.title')}
                  description={t('meta.desc')}
                  keyword={t('meta.keyword')}
                  lang={props.currentLocale} />
            <Banner type='company' />
            <div id='Introduce' />
            <Tabs {...props} tabItems={tabItems} type={'hashlink'}/>
            <Section1>
              <Sec1Inner>
                <Sec1DescInner>
                    <Fade bottom delay={200} duration={1500}>
                        <Sc1DesLfInner>        
                            <CompanyImg
                                role='img'
                                aria-label={alt[0]} />
                        </Sc1DesLfInner>
                    </Fade>
                    <Sc1DesRhInner>
                        <Sc1DesRhTop>
                            <Fade bottom delay={400} duration={1500}>
                                {section1List.map((list, index) => {
                                    return ( 
                                        <Sc1DesRhTopText key={String(index)}>
                                            {t(list.left)}&nbsp;&nbsp;
                                            <TopTextLeft>{t(list.right)}
                                                <PLine pcwidth={t(list.pwidth)} mowidth={t(list.mwidth)}/>
                                            </TopTextLeft>
                                        </Sc1DesRhTopText> 
                                    )
                                })}
                            </Fade>
                        </Sc1DesRhTop>
                        <Fade bottom delay={400} duration={1500}>
                            <Sec1DescRightCenter>
                                <CenterText>{(props.currentLocale && isMobile) ? t('section1.subtitle.mo') : t('section1.subtitle.pc')}</CenterText>
                            </Sec1DescRightCenter>
                        </Fade>
                        <Fade bottom delay={400} duration={1500}>
                            <Sec1DescRightBottom>
                                <BottomText lang={props.currentLocale ? true : false} word={props.currentLocale}>{t('section1.desc')}</BottomText>
                                <HashMessagePC id='message' />
                            </Sec1DescRightBottom>
                        </Fade>
                    </Sc1DesRhInner>
                </Sec1DescInner>
              </Sec1Inner>
          </Section1>
          <Section2>
            <Sec2TitleInner>
                <Fade bottom delay={400} duration={1500}>
                    <Sec2TopTitle><TopPoint>{t('section2.title.left')}</TopPoint> {t('section2.title.right')}</Sec2TopTitle>
                </Fade>
                
                <Fade bottom delay={400} duration={1500}>
                    <Sec2TopSubTitle>{t('section2.subtitle')}</Sec2TopSubTitle>
                </Fade>
            </Sec2TitleInner>
            <Sec2DescInner>
                <Fade bottom delay={400} duration={1500}>
                    <Sec2DescLeftInner>
                        <Sec2LeftDesc>{t('section2.desc1.content')}</Sec2LeftDesc>
                        <Sec2Name top={t('section2.desc1.top')}>{t('section2.desc1.author')}</Sec2Name>
                    </Sec2DescLeftInner>
                </Fade>
                <Fade bottom delay={600} duration={1500}>
                    <Sec2DescRightInner>
                        <Sec2RightDesc>{t('section2.desc2.content')}</Sec2RightDesc>
                        <Sec2Name top={t('section2.desc2.top')}>{t('section2.desc2.author')}</Sec2Name>
                        <HashVisionPC id='vision' />
                    </Sec2DescRightInner>
                </Fade>
            </Sec2DescInner>
          </Section2>
          <Section3>
              <Sec3Inner>
                <Fade bottom delay={300} duration={1500}>
                    <Sec3TopTitle><TopPoint>{t('section3.title')}</TopPoint></Sec3TopTitle>
                </Fade>
                <Fade bottom delay={300} duration={1500}>
                    <Sec3TopSubTitle>{t('section3.subtitle')}</Sec3TopSubTitle>
                </Fade>
                <Fade bottom delay={400} duration={1500}>
                    <Sc301TitleInner>
                        <Sec3Dot />
                        <Sec3Title>5 STANDARD</Sec3Title>
                        <Sec3Dot />
                    </Sc301TitleInner>
                </Fade>
                <Sec3PCMenu>
                    <Fade bottom delay={400} duration={1500}>
                        {t('section3.standard', { returnObjects: true }).map((contants, index) => {
                            return <Sec3Sj01Itemimg key={String(index)} src={contants} alt={alt[index+1]} />
                        })}
                    </Fade>
                </Sec3PCMenu>

                <Sec3PCSJInner>
                    <Fade bottom delay={400} duration={1500}>
                        <Sec3Sj02Img src={t('section3.execution', { returnObjects: true }).image.pc} alt={alt[6]} />
                    </Fade>
                </Sec3PCSJInner>

                <Sec3MBSJInner>
                    <Sec3MBTitleInner>
                        <Fade bottom delay={400} duration={1500}>
                            <Sec3Dot />
                            <Sec3Title>10 EXECUTION VALUE</Sec3Title>
                            <Sec3Dot />
                        </Fade>
                    </Sec3MBTitleInner>
                    <Sec3MBImgInner>
                        <Fade bottom delay={400} duration={1500}>
                            <Sec3Sj02Img src={t('section3.execution', { returnObjects: true }).image.mo} alt={alt[6]} />
                        </Fade>
                    </Sec3MBImgInner>
                </Sec3MBSJInner>
                <HashHistoryPC id='history' />
            </Sec3Inner>
          </Section3>
          <Section4>
            
            <Sec4Inner>
                <Sec4TopTitle><TopPoint>{t('section4.title')}</TopPoint></Sec4TopTitle>
                <Sec4TopSubTitle>{t('section4.subtitle')}</Sec4TopSubTitle>
              </Sec4Inner>
            
              <Sec4BtnInner >
                <PrevBtn isindex={index} isindexmb={indexMB} onClick={onClickPrev} />
                <NextBtn isindex={index} isindexmb={indexMB} onClick={onClickNext} />
              </Sec4BtnInner>

              <HistoryLine />
              
                <HistoryInner>
                    <Slider {...settings}>
                        {t('section4', { returnObjects: true }).items.map((item, idx, arr) => {
                            let count = (idx + 3) % 3
                            if (count === 0) {
                                return (
                                    <HistorySlider key={String(idx)}>
                                        <HistoryWrapper>
                                            <HistoryContent isEnd={idx >= 12}>
                                                <CompanyHistory isEnd={idx >= 12} year={item.year} desc={item.desc} idx={idx} lang={props.currentLocale} />
                                                {(arr.length > (idx + 1)) && (
                                                    <CompanyHistory year={arr[idx+1].year} desc={arr[idx+1].desc} idx={idx+1} lang={props.currentLocale} />
                                                )}
                                                {(arr.length > (idx + 2)) && (
                                                    <CompanyHistory year={arr[idx+2].year} desc={arr[idx+2].desc} idx={idx+2} lang={props.currentLocale} />
                                                )}
                                            </HistoryContent>
                                        </HistoryWrapper>
                                    </HistorySlider>
                                )
                            } else {
                                return null
                            }
                        })}
                    </Slider>
                </HistoryInner>

                <HistoryInnerMB>
                    <Slider {...settingsMB}>
                        {t('section4', { returnObjects: true }).items.map((item, idx, arr) => {
                            return (
                                <div key={String(idx)} style={{ width: '5%'}}>
                                    <HistorySlider>
                                        <HistoryWrapper>
                                            <HistoryContent>
                                                <CompanyHistory year={item.year} desc={item.desc} idx={idx} lang={props.currentLocale} />
                                            </HistoryContent>
                                        </HistoryWrapper>
                                    </HistorySlider>
                                </div>
                                
                            )
                        })}
                    </Slider>
                </HistoryInnerMB>
          </Section4>
        </Wrapper>
    )
}

const Wrapper = styled.div`
`
// hashtag
const HashMessagePC = styled.div`
    &:before {
            content: " "; 
            display : block;
            margin-top: -130px; 
            height: 130px; 
            visibility: hidden; 
            pointer-events: none;
        }
    @media screen and (max-width: 1000px) {
        &:before {
            content: " "; 
            display : block;
            margin-top: -130px; 
            height: 130px; 
            visibility: hidden; 
            pointer-events: none;
        }
    }
    @media screen and (max-width: 767px) {
        &:before {
            content: " "; 
            display : block;
            margin-top: -100px; 
            height: 100px; 
            visibility: hidden; 
            pointer-events: none;
        }
    }
`

const HashVisionPC = styled.div`
    display : block;
    @media screen and (max-width: 1000px) {
        &:before {
            content: " "; 
            display : block;
            margin-top: -60px; 
            height: 60px; 
            visibility: hidden; 
            pointer-events: none;
        }
    }
    @media screen and (max-width: 767px) {
        &:before {
            content: " "; 
            display : block;
            margin-top: -30px; 
            height: 30px; 
            visibility: hidden; 
            pointer-events: none;
        }
    }
`

const HashHistoryPC = styled.div`
    &:before {
            content: " "; 
            display : block;
            margin-top: -100px; 
            height: 100px; 
            visibility: hidden; 
            pointer-events: none;
        }
    @media screen and (max-width: 1000px) {
        &:before {
            content: " "; 
            display : block;
            margin-top: -60px; 
            height: 60px; 
            visibility: hidden; 
            pointer-events: none;
        }
    }
    @media screen and (max-width: 767px) {
        &:before {
            content: " "; 
            display : block;
            margin-top: -90px; 
            height: 90px; 
            visibility: hidden; 
            pointer-events: none;
        }
    }
`



// *************
// Section1
// *************
const Section1 = styled.div`
    width : 100%;
    display : flex;
    justify-content : center;

    @media screen and (max-width: 1000px) {
        height : auto;
        display : block;
    }
`
const Sec1Inner = styled.div`
    max-width : 1200px;

    @media screen and (max-width: 1199px) {
        width : 100%;
    }
`
const Sec1DescInner = styled.div`
    margin : 70px 32px 0px 32px;
    display : flex;

    @media screen and (max-width: 1000px) {
        flex-direction : column;
        justify-content: center;
        width : 100%;
        margin : 0;
        padding : 15px 32px 0px 32px;
    }
`

const Sc1DesLfInner = styled.div`
    width : 544px;
    height : 485px;
    margin-right : 60px;

    @media screen and (max-width: 1000px) {
        width : 100%;
        height : auto;
        display : flex;
        justify-content : center;
        margin-right : 0px;
    }
`
const CompanyImg = styled.div`
    width : 544px;
    height : 100%;
    background-image : url(${company_01});
    background-size : cover;
    background-repeat : no-repeat;
    background-position : 20% 80%;

    @media screen and (max-width: 1000px) {
        width : 100%;
        height : 306px;
        margin-right : 0;
    }
`

const Sc1DesRhInner = styled.div`
    width : 532px;

    @media screen and (max-width: 1000px) {
        width : 100%;
        margin-top : 28px;
        padding : 0px 12px;
    }
`
const Sc1DesRhTop = styled.div`
    line-height: 1.39;

    @media screen and (max-width: 1000px) {
        width : 100%;
        margin-top : 10px;
    }
`
const TopTextLeft = styled.span`
    position : relative;
    font-size: 38px;
    font-weight: 900;

    @media screen and (max-width: 1000px) {
        font-size: 30px;
    }
`
const PLine = styled.div`
    position : absolute;
    width : ${props => props.pcwidth || '188px'};
    height : 9px;
    opacity : 0.2;
    border-radius : 12px;
    background-image: linear-gradient(96deg, #4e00ff -30%, #ac0093 109%);
    right: -6px;
    bottom: 8px;
    z-index : 2;

    @media screen and (max-width: 1000px) {
        width : ${props => props.mowidth || '140px'};
    }
`
const Sc1DesRhTopText = styled.div`
    font-size: 32px;
    font-weight: 500;

    @media screen and (max-width: 1000px) {
        font-size: 20px;
        font-weight: 600;
    }
`
const Sec1DescRightCenter = styled.div`
    margin-top : 30px;

    @media screen and (max-width: 1000px) {
        margin-top : 40px;
    }
`
const CenterText = styled.div`
    font-size: 26px;
    font-weight: 900;
    line-height: 1.46;
    letter-spacing: -0.26px;
    color: #222;
    white-space : pre-wrap;

    @media screen and (max-width: 1000px) {
        font-size: 20px;
        line-height: 1.5;
        letter-spacing: normal;
    }
`

const Sec1DescRightBottom = styled.div`
    margin-top : 30px;

    @media screen and (max-width: 1000px) {
        margin-top : 28px;
    }
`
const BottomText = styled.div`
    font-size: ${props=>props.lang ? '18px' : '20px'};
    line-height: ${props=>props.lang ? '1.7' : '1.6'};
    color: #222;
    word-break : ${props => props.word === '/jp' ? 'normal' : 'keep-all'};
    margin-bottom : 100px;


    @media screen and (max-width: 1000px) {
        font-size: 14px;
        font-weight: normal;
        line-height: 1.57;
        margin-bottom : 60px;
    }
`

// *************
// Section2
// *************

const Section2 = styled.div`
    width : 100%;
    display : flex;
    flex-direction : column;
    align-items : center;
    background : #f9f9f9;

    @media screen and (max-width: 1000px) {
    }
`

const Sec2TitleInner = styled.div`
    margin-top : 79px;
    width : 1200px;

    @media screen and (max-width: 1199px) {
        width : 100%;
    }

    @media screen and (max-width: 1000px) {
        margin-top :48px;
        width : 100%;
        padding : 0px 28px;
    }
`

const Sec2TopTitle = styled.div`
    font-size: 40px;
    font-weight: 900;
    line-height: 1.18;
    letter-spacing: -0.4px;
    color: #222;

    @media screen and (max-width: 1000px) {
        font-size: 28px;
        line-height: 1.29;      
    }
`

const TopPoint = styled.span`
    color: #8f0a7c;
`

const Sec2TopSubTitle = styled.div`
    margin-top : 2px;
    font-size: 22px;
    font-weight: 500;
    line-height: 2.18;
    letter-spacing: -0.22px;
    color: #222;

    @media screen and (max-width: 1000px) {
        margin-top : 20px;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.57;
        letter-spacing: normal;
    }
`

const Sec2DescInner = styled.div`
    position : relative;
    margin-top : 20px;
    margin-bottom : 81px;
    width : 1200px;
    /* height : 575px; */
    display : flex;

    &:before {
        content: " ";
        width : 106px;
        height : 86px;
        position : absolute;
        background-image : url(${section2_img1});
    }

    &:after {
        content: " ";
        width : 106px;
        height : 86px;
        position : absolute;
        right: 0px;
        bottom: 36px;
        background-image : url(${section2_img2});

        @media all and (max-width:1000px) {
            bottom: 0px;
            right: 20px;
        }
    }

    @media all and (max-width:1199px) {
        width : 100%;
    }

    @media all and (max-width:1000px) {
        margin-top : 40px;
        margin-bottom : 48px;
        width : 100%;
        height : auto;
        display : block;
        padding : 0px 16px;
    }
`


const Sec2DescLeftInner = styled.div`
    margin-left : 32px;

    @media all and (max-width:1000px) {
        margin-left : 0px;
    }
`

const Sec2Name = styled.div`
    margin-top : ${props => props.top || '50px'};
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    color: #222;

    @media all and (max-width:1000px) {
        font-size: 16px;
        line-height: 1.5;
        margin-top : 28px;
        padding : 0px 12px;
    }
`

const Sec2LeftDesc = styled.div`
    margin-top : 43px;
    width: 520px;
    font-size: 16px;
    line-height: 1.5;
    color: #222;
    white-space : pre-wrap;

    @media all and (max-width:1199px) {
        width: 100%;
    }

    @media all and (max-width:1000px) {
        margin-top : 20px;
        width : 100%;
        font-size: 14px;
        line-height: 1.57;
        padding : 0px 12px;
    }
`

const Sec2DescRightInner = styled.div`
    margin-left : 96px;

    @media all and (max-width:1199px) {
        margin-left : 4vw;
    }

    @media all and (max-width:1000px) {
        margin-left : 0px;
    }

`

const Sec2RightDesc = styled.div`
    margin-top : 43px;
    width: 520px;
    font-size: 16px;
    line-height: 1.5;
    color: #222;
    white-space: pre-wrap;

    @media all and (max-width:1199px) {
        width: 100%;
    }

    @media all and (max-width:1000px) {
        margin-top : 80px;
        width: 100%;
        font-size: 14px;
        line-height: 1.57;
        padding : 0px 12px;
    }
`

// *************
// Section3
// *************
const Section3 = styled.div`
    width : 100%;
    display : flex;
    justify-content : center;
`
const Sec3Inner = styled.div`
    width : 1200px;

    @media all and (max-width:1199px) {
        width : 100%;
    }
`
const Sec3TopTitle = styled.div`
    margin-top : 100px;
    font-size: 40px;
    font-weight: 900;
    line-height: 1.18;
    letter-spacing: -0.4px;

    @media all and (max-width:1000px) {
        margin-top : 60px;
        font-size: 28px;
        line-height: 1.29;
        padding : 0px 28px;
    }
`
const Sec3TopSubTitle = styled.div`
    font-size: 22px;
    font-weight: 500;
    line-height: 2.18;
    letter-spacing: -0.22px;
    color: #222;

    @media all and (max-width:1000px) {
        margin-top : 8px;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.57;
        padding : 0px 28px;
    }
`
const Sc301TitleInner = styled.div`
    margin-top : 40px;
    display : flex;
    justify-content : center;
    align-items: center;

    @media all and (max-width:1000px) {
        margin-top : 32px;
    }
`

const Sec3Dot = styled.div`
    width: 6px;
    height: 6px;
    background-color: #8f0a7c;
    border-radius : 50px;
`

const Sec3Title = styled.div`
    margin-left : 12px;
    margin-right : 12px;
    font-size: 28px;
    font-weight: 900;
    line-height: 1.96;
    letter-spacing: -0.28px;
    color: #222;

    @media all and (max-width:1000px) {
        font-size: 24px;
        line-height: 1.33;
    }
`


const Sec3PCMenu = styled.div`
    margin-top : 59px;
    width : 100%;
    display : flex;
    justify-content : space-between;

    @media all and (max-width:1199px) {
        margin-top : 24px;
        height : auto;
        padding : 0px 28px;
    }
    @media all and (max-width:820px) {
        justify-content : flex-start;
        flex-wrap : wrap;
        justify-content : center;
    }

    @media all and (max-width:521px) {
        justify-content : flex-start;
        padding : 0px 37px;
    }
`

const Sec3Sj01Itemimg = styled.img`
    width : 216px;
    height : 100%;

    @media all and (max-width:1199px) {
        width : 150px;
        height : 100%;
    }

    @media all and (max-width:820px) {
        padding : 7px 7px;
    }
`


const Sec3PCSJInner = styled.div`
    margin-top : 100px;
    margin-bottom : 100px;
    width : 100%;
    display : flex;
    flex-direction : column;
    align-items : center;
    
    @media all and (max-width:820px) {
        display : none;
    }
`

const Sec3MBSJInner = styled.div`
    display : none;
    @media all and (max-width:820px) {
        display : flex;
        flex-direction : column;
        justify-content : center;  
        align-items : center;
        margin-top : 80px;
        width : 100%;
        padding : 0px 28px;
    }

`
const Sec3MBTitleInner = styled.div`
    @media all and (max-width:820px) {
        display : flex;
        justify-content : center;
        align-items :center;
        margin-bottom : 24px;
    }
`

const Sec3MBImgInner = styled.div`
    @media all and (max-width:820) {
    }
`

const Sec3Sj02Img =styled.img`
    width : 100%;
    height : 100%;

    @media all and (max-width:820px) {
        margin-bottom : 100px;
    }
`

// *************
// Section4
// *************
const Section4 = styled.div`
    width : 100%;
    display : flex;
    flex-direction : column;
    align-items : center;
    background : #f9f9f9;

    @media all and (max-width:1000px) {
        padding-left : 28px; 
    }
`

const Sec4Inner = styled.div`
    margin-top : 80px;
    width : 1200px;
    
    @media all and (max-width:1199px) {
        margin-top : 100px;
        width : 100%;
    }

    @media all and (max-width:1000px) {
        margin-top : 48px;
    }
`

const HistoryLine = styled.div`
    width: 100%;
    height: 8px;
    background-image: linear-gradient(92deg, #46ff98 -8%, #ab86ff 25%, #8f0a7c 106%);

    @media all and (max-width:1000px) {
    }
`

const Sec4TopTitle = styled.div`
    /* margin-left : 60px; */
    font-size: 40px;
    font-weight: 900;
    line-height: 1.18;
    letter-spacing: -0.4px;

    @media all and (max-width:1000px) {
        margin-left : 0px;
        font-size: 28px;
        line-height: 1.29;
    }
`

const Sec4TopSubTitle = styled.div`
    margin-top : 2px;
    /* margin-left : 60px; */
    font-size: 22px;
    font-weight: 500;
    line-height: 2.18;
    letter-spacing: -0.22px;
    color: #222;

    @media all and (max-width:1000px) {
        margin-top : 8px;
        margin-left : 0px;
        font-size: 14px;
        font-weight: bold;
        line-height: 1.57;
    }
`

const Sec4BtnInner = styled.div`
    margin-top : 59px;
    margin-bottom : 19px;
    width : 1200px;
    display : flex;

    @media all and (max-width:1199px) {
        width : 100%;
    }

    @media all and (max-width:1000px) {
        margin-top : 53px;
        width : 100%;
        justify-content : space-between;
        padding-right : 28px;
    }
`

const PrevBtn = styled.div`
    /* margin-left : 60px; */
    width : 44px;
    height :44px;
    background-image : url(${props => (props.isindex === 0)  ? prev_btn : prev_btn_on});
    background-size : cover;
    cursor: pointer;

    @media all and (max-width:1000px) {
        margin-left : 0px;
        background-image : url(${props => (props.isindexmb === 0)  ? prev_btn : prev_btn_on});
        background-size : cover;
    }
`

const NextBtn = styled.div`
    margin-left : 56px;
    width : 44px;
    height :44px;
    background-image : url(${props => (props.isindex === 4) ? next_btn : next_btn_on});
    background-size : cover;
    cursor: pointer;

    @media all and (max-width:1000px) {
        margin-left : 0px;
        background-image : url(${props => (props.isindexmb === 13)  ? next_btn : next_btn_on});
        background-size : cover;
    }
`

const HistoryInner = styled.div`
    width : 100%;
    margin-bottom : 200px;

    @media all and (max-width:1000px) {
        display : none;
    }
`

const HistoryInnerMB = styled.div`
    display : none;

    @media all and (max-width:1000px) {
        width : 100%;
        margin-bottom : 200px;
        display : block;
    }
`

const HistorySlider = styled.div`
    width: auto;
`

const HistoryWrapper = styled.div`
    width : 100%;
    display : flex;
    flex-direction :column;
    align-items : center;
`

const HistoryContent = styled.div`
    margin-top : 40px;
    width : 1200px;
    display : flex;
    justify-content : ${props=>props.isEnd ? 'flex-start' : 'space-between'};

    @media screen and (max-width: 1199px) {
        width : 100%;
    }

    @media all and (max-width:1000px) {
        width : auto;
        margin-top : 24px;
    }
`

export default withRouter(Company)