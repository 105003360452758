import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react';
import './index.css';
import App from './App';
import { hydrate, render } from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import './libs/i18n'
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module'

ReactGA.initialize('UA-137815452-3');
ReactGA.plugin.require('displayfeatures');
ReactGA.pageview(window.location.pathname);
const tagManagerArgs = {
    gtmId: 'GTM-N7JH8W5'
}
  
TagManager.initialize(tagManagerArgs)

const rootElement = document.getElementById('root')

if (!!rootElement && rootElement.hasChildNodes()) {
  hydrate(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>,
    rootElement
  )
} else {
  render(
    <React.StrictMode>
      <Router>
        <App />
      </Router>
    </React.StrictMode>,
    rootElement
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
