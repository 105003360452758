import axios from 'axios'
import baseUrl from './base.json'

const env = process.env.API_ENV || 'development';


export function getMediaList(params, url) {
    return axios({
        method: 'get',
        url: url,
        baseURL: baseUrl[env].baseUrl,
        params: params,
    })
}

export function getMediaDetail(params, url) {
    return axios({
        method: 'get',
        url: url,
        baseURL: baseUrl[env].baseUrl,
        params: params,
    })
}

export function getMediaPreNext(params, url) {
    return axios({
        method: 'get',
        url: url,
        baseURL: baseUrl[env].baseUrl,
        params: params,
    })
}

export function getMediaRelative(params, url) {
    return axios({
        method: 'get',
        url: url,
        baseURL: baseUrl[env].baseUrl,
        params: params,
    })
}