import React from "react";
import styled from "styled-components";
import logo from '../images/common/footer_logo.png'
import icon01 from '../images/common/footer_icon01.png'
import icon02 from '../images/common/footer_icon02.png'
import icon03 from '../images/common/footer_icon03.png'
import { withRouter, Link } from 'react-router-dom'
import 'antd/dist/antd.css';
import { Menu, Dropdown } from 'antd';
import dropdownimg from '../images/common/footer_dropdwon.png'
import { useTranslation } from 'react-i18next'

const Footer = ({ ...props }) => {
    const { t } = useTranslation('common')
    const menu = (
        <Menu >
          <Menu.Item key="0">
            <a href={t('footer.site.viosys.href')} target='_blank' >{t('footer.site.viosys.title')}</a>
          </Menu.Item>
          <Menu.Item key="1">
            <a href={t('footer.site.seti.href')} target='_blank'>{t('footer.site.seti.title')}</a>
          </Menu.Item>
          <Menu.Item key="2">
            <a href={t('footer.site.semicon.href')} target='_blank'>{t('footer.site.semicon.title')}</a>
          </Menu.Item>
        </Menu>
      );

    const footerList = t('footer', { returnObjects: true }).menu


    return (
        <Wrapper isStatus={props.location.pathname !== `${props.currentLocale}/showroom`} >
            <LogoInner>
                <LeftIconInner>
                    <a href={t('footer.social.facebook.href')} target="_blank">
                        <Icon src={icon02} alt={t('footer.social.facebook.alt')} />
                    </a>
                    <a href={t('footer.social.youtube.href')} target="_blank">
                        <Icon src={icon03} alt={t('footer.social.youtube.alt')} />
                    </a>
                    <a href={t('footer.social.linkedin.href')} target="_blank">
                        <Icon src={icon01} alt={t('footer.social.linkedin.alt')} />
                    </a>
                </LeftIconInner>
                <a href={t('footer.logo.href')} target="_self" >
                    <LogoImg src={t('footer.logo.image', { returnObjects: true })} alt={t('footer.logo.alt')} />
                </a>
                <RightMenuInner>
                    <SiteMenu>
                        <DropdownMenu overlay={menu} trigger={['click']} placement="bottomCenter">
                            <DropDownLink className="ant-dropdown-link" 
                                          onClick={e => e.preventDefault()}
                                          >
                                    {t('footer.site.label')}
                                <DropDownimg src={dropdownimg} alt="더보기" />
                            </DropDownLink>
                        </DropdownMenu>
                    </SiteMenu>
                </RightMenuInner>
            </LogoInner>
            <BottomText>Copyright© 2021 Seoul Viosys Co., Ltd. All Right Reserved.</BottomText>
            <FooterMenu>
                <FooterUl>
                    {footerList.map( (list, index) => {
                        return (
                        <FooterLi key={String(index)}>
                            <FooterLink href={list.link} target="_blank" key={index}>{list.text}</FooterLink>
                        </FooterLi> 
                        )
                    })}
                </FooterUl>
            </FooterMenu>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: block;
    width : 100%;
    /* height : 360px; */
    display : ${ props => props.isStatus ? 'flex' : 'none'};
    flex-direction : column;
    align-items : center;
    background-color : #000;

    @media screen and (max-width: 767px) {
        display: none;
    }
`

const LeftIconInner = styled.div`
    width : 200px;
    height : 100%;
    display : flex;
    justify-content : flex-start;
    align-items : center;
`

const RightMenuInner = styled.div`
`

const Icon = styled.img`
    width : 24px;
    height : 24px;
    margin-right: 16px;
`

const LogoInner = styled.div`
    margin-top : 21px;
    width: 1160px;
    height : 80px;
    display : flex;
    justify-content: space-between;
    align-items : center;

    @media all and (max-width:1199px) {
        width: 100%;
    }
`

const LogoImg = styled.img`
    height : 32px;
`

const SiteMenu = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    width : 200px;
    height : 33px;
    border : solid 1px #fff;
    padding : 0px 10px 0px 16px;
`

const DropdownMenu = styled(Dropdown)`

    &:hover {
        color : white;
    }
`

const DropDownLink = styled.a`
    font-size: 12px;
    line-height: 1.5;
    color: #aaa;
    display : flex;
    align-items : center;

    
`

const DropDownimg = styled.img`
    margin-left : 8px;
    width : 17px;
    height : 17px;
`

const BottomText = styled.div`
    width : 100%;
    display : flex;
    justify-content : center;
    margin-top : 40px;
    margin-bottom : 28px;
    font-size: 10px;
    color: #aaa;
`

const FooterMenu = styled.div`
    padding: 21px 0;
    margin: 0 5%;
    text-align: center;
    border-top: 1px solid #222;
    width : 80%;
`

const FooterUl = styled.ul`
    color: #aaa;
    display : flex;
    justify-content : center;
`

const FooterLi = styled.li`
    padding: 7px 17px 7px 22px;
    text-decoration : none;
`

const FooterLink = styled.a`
    text-decoration : none;
    color: #777;

    &:hover {
        color : #aaa;
    }
`

export default withRouter(Footer)